export default function toHierarchy(tasks, relations) {
  tasks = tasks
    .filter(task => {
      if (task.project) {
        return task.project.state === 'NEW'
      }
      return true
    })
    .map(task => ({
    ...task,
    children: [],
    parent: null,
  }));

  return tasks.reduce((rootTasks, task) => {
    const parentIds = relations
      .filter(({ to_id }) => to_id === task.id)
      .map(relation => relation.from_id);
    
    const parents = tasks.filter(
      ({ id }) => parentIds.indexOf(id) !== -1
    );

    if (parents.length) {
      task.parent = parents[0];
      parents.forEach(parent => parent.children.push(task));
    } else {
      rootTasks.push(task);
    }

    return rootTasks;
  }, []);
}
