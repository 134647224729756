import { PRODUKTIONSWARE, VERBRAUCHSMATERIAL, EINKAUFSWARE, LAGER_UND_PRODUKTION, LAGERWARE, BESTANDSWARE, WERKZEUG, INHOUSE } from '../material/materialCategories'

export default [
  PRODUKTIONSWARE,
  LAGERWARE,
  WERKZEUG,
  VERBRAUCHSMATERIAL,
  BESTANDSWARE,
  EINKAUFSWARE,
  //INHOUSE
]