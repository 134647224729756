
import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import api from '@/util/api.js'

export default merge({
  state: {
    globalOptions: []
  },
  getters: {
    globalOptions: state => state.globalOptions || []
  },
  mutations: {
    globalOptions: (state, options) => state.globalOptions = options
  },
  actions: {
    loadProjectOptions({ commit, dispatch }, projektId) {
      return api('findOptions', { commit: false })
        .and('project_id', projektId)
        .run()
        .then(items => {
          commit('options', items)
          return items
        })
    },
    loadGlobalOptions({ commit, dispatch }) {
      return api('findOptions', { commit: false })
        .and('project_id', null)
        .run()
        .then(items => {
          commit('globalOptions', items)
          return items;
        })
    }
  }

}, Base('option'))
