<template>
  <div>
    <quill-editor @change="onEditorChange" :content="value" ref="myQuillEditor" :options="editorOption" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Quill from "quill"
import QuillMention from "quill-mention"
import QuillEmoji from 'quill-emoji'

Quill.register('modules/quill-emoji', QuillEmoji);
Quill.register("modules/mention", QuillMention);

export default {
  name: "SocialInput",
  props: {
    placeholder: {
      type: String,
      default: "Type here"
    },
    content: {
      type: String,
      default: "Type here"
    },
    value: {
      type: String
    },
    atValues: {
      type: Array,
      default: () => {
        return [];
      },
      optional: true
    },
    hashValues: {
      type: Array,
      default: () => {
        return [];
      },
      optional: true
    }
  },
  model: {
    prop: "value",
    event: "update"
  },
  data() {
    return {
      editorOption: {
        placeholder: "Kommentar schreiben",
        modules: {
          toolbar: {
            container: [
              ['emoji'],
            ],
            handlers: { 'emoji': function () { } }
          },
          keyboard: {
            bindings: {
              custom: {
                key: 186,
                shiftKey: true,
                handler: function (range, context) {
                  this.quill.insertText(range.index, 'Ü');
                }
              }
            }
          },
          "emoji-shortname": true,
          "emoji-toolbar": true,
          "emoji-textarea": true
        }
      }
    };
  },
  created() {
    // NOTE: Set default option if not existence in props
    // for(let key in this.defaultOption) {
    //   const value = this.option[key]
    //   if (value === undefined) {
    //     this.option[key] = this.defaultOption[key]
    //   }
    // }
    const self = this;
    this.editorOption.placeholder = this.placeholder;
    this.editorOption.modules.mention = {
      defaultMenuOrientation: 'top',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@",'#'],
      
      source: (searchTerm, renderList, mentionChar) => {
        let values = mentionChar === "@" ? self.atValues : self.hashValues;
        if (searchTerm.length) {
          const search = searchTerm.toLowerCase();
          values = values.filter(
            ({ value }) => ~value.toLowerCase().indexOf(search)
          );
        }
        renderList(values, searchTerm);
      }
    };
  },
  mounted() {
    // NOTE: Create Observer. This is called when text content of the target is changed

  },
  computed: {},
  methods: {
    onEditorChange(event) {
      this.$emit("update", event.html)
    }
  },
  watch: {
    hashtagList: function (newVal, oldVal) {
      const isEqualVal = JSON.stringify(newVal) === JSON.stringify(oldVal);
      if (!isEqualVal) {
        const hashtagData = {};
        if (newVal === null) {
          hashtagData.target = "";
        } else if (oldVal === null) {
          // NOTE: Set focused hashtag node
          const hashtagTarget = this.getHashtagTargetNode(0);
          hashtagData.target = hashtagTarget.innerText;
          this.focusedHashtagNode = hashtagTarget;
        } else {
          if (newVal.length < oldVal.length) {
            hashtagData.target = "";
          } else {
            // NOTE: Set focused hashtag node
            const diff = this.getDiffArrayWithIndex(newVal, oldVal);
            const index = diff.diffValue === undefined ? newVal.length - 1 : diff.index;
            const hashtagTarget = this.getHashtagTargetNode(index);
            hashtagData.target = hashtagTarget.innerText;
            this.focusedHashtagNode = hashtagTarget;
          }
        }

        hashtagData.hashtags = newVal;
        this.$emit("onChangeHashtag", hashtagData);
      }
    },
    value(nv, ov) {
      if (ov === '' && nv !== '') {
        this.$nextTick(() => {
          this.$refs.myQuillEditor.quill.setSelection(nv.length + 1, 'user');
        })
      }
    }
  }
};
</script>

<style>
.emoji-tab.filter-nature,
.emoji-tab.filter-food,
.emoji-tab.filter-symbols,
.emoji-tab.filter-objects,
.emoji-tab.filter-flags {
  display: none !important;
}

.bem,
.ap {
  margin: 6px 3px;
  background-image: url(../../assets/images/emoji_sheet.png);
  font-size: 0;
  text-indent: -99999px;
}

span.ql-emojiblot>span {
  line-height: 0;
  font-size: 0;
  display: inline-table;
}

span.ql-emojiblot>span .ap {
  margin: -3px 0 0 0;
}

.bem.bem-grinning,
.bem.bem-grin,
.bem.bem-kissing,
.bem.bem-kissing_smiling_eyes,
.bem.bem-kissing_closed_eyes,
.bem.bem-neutral_face,
.bem.bem-expressionless,
.bem.bem-no_mouth,
.bem.bem-smirk,
.bem.bem-open_mouth,
.bem.bem-hushed,
.bem.bem-sleepy,
.bem.bem-tired_face,
.bem.bem-relieved,
.bem.bem-nerd_face,
.bem.bem-stuck_out_tongue,
.bem.bem-stuck_out_tongue_closed_eyes,
.bem.bem-sweat,
.bem.bem-pensive,
.bem.bem-upside_down_face,
.bem.bem-astonished,
.bem.bem-slightly_frowning_face,
.bem.bem-confounded,
.bem.bem-disappointed,
.bem.bem-worried,
.bem.bem-triumph,
.bem.bem-cry,
.bem.bem-sob,
.bem.bem-frowning,
.bem.bem-anguished,
.bem.bem-weary,
.bem.bem-fearful,
.bem.bem-dizzy_face,
.bem.bem-flushed,
.bem.bem-cold_sweat,
.bem.bem-angry,
.bem.bem-smiling_imp,
.bem.bem-imp,
.bem.bem-japanese_ogre,
.bem.bem-japanese_goblin,
.bem.bem-skull,
.bem.bem-ghost,
.bem.bem-alien,
.bem.bem-robot_face,
.bem.bem-smiley_cat,
.bem.bem-smile_cat,
.bem.bem-joy_cat,
.bem.bem-heart_eyes_cat,
.bem.bem-smirk_cat,
.bem.bem-scream_cat,
.bem.bem-kissing_cat,
.bem.bem-crying_cat_face,
.bem.bem-pouting_cat,
.bem.bem-boy,
.bem.bem-girl,
.bem.bem-man,
.bem.bem-woman,
.bem.bem-older_man,
.bem.bem-older_woman,
.bem.bem-baby,
.bem.bem-angel,
.bem.bem-cop,
.bem.bem-sleuth_or_spy,
.bem.bem-guardsman,
.bem.bem-construction_worker,
.bem.bem-man_with_turban,
.bem.bem-person_with_blond_hair,
.bem.bem-santa,
.bem.bem-princess,
.bem.bem-bride_with_veil,
.bem.bem-man_with_gua_pi_mao,
.bem.bem-ok_woman,
.bem.bem-information_desk_person,
.bem.bem-person_frowning,
.bem.bem-massage,
.bem.bem-haircut,
.bem.bem-couple_with_heart,
.bem.bem-couplekiss,
.bem.bem-speaking_head_in_silhouette,
.bem.bem-bust_in_silhouette,
.bem.bem-busts_in_silhouette,
.bem.bem-walking,
.bem.bem-two_men_holding_hands,
.bem.bem-two_women_holding_hands,
.bem.bem-dancers,
.bem.bem-dancer,
.bem.bem-runner,
.bem.bem-family,
.bem.bem-couple,
.bem.bem-bow,
.bem.bem-point_left,
.bem.bem-point_right,
.bem.bem-raised_hand_with_fingers_splayed,
.bem.bem-point_up_2,
.bem.bem-point_down,
.bem.bem-middle_finger,
.bem.bem-the_horns,
.bem.bem-fist,
.bem.bem-wave,
.bem.bem-writing_hand,
.bem.bem-open_hands,
.bem.bem-raised_hands,
.bem.bem-nail_care,
.bem.bem-pray,
.bem.bem-ear,
.bem.bem-nose,
.bem.bem-footprints,
.bem.bem-eyes,
.bem.bem-eye,
.bem.bem-tongue,
.bem.bem-lips,
.bem.bem-kiss,
.bem.bem-eyeglasses,
.bem.bem-dark_sunglasses,
.bem.bem-mortar_board,
.bem.bem-helmet_with_white_cross,
.bem.bem-lipstick,
.bem.bem-ring,
.bem.bem-closed_umbrella,
.bem.bem-briefcase {
  display: none !important;
}

.ap-zzz {
  background-position: -400px -780px;
  /* computer */
}

.ap-necktie {
  background-position: -420px -660px;
  /* telephone_receiver */
}

.ap-shirt {
  background-position: -0px -500px;
  /* alarm_clock */
}

.ap-jeans {
  background-position: -180px -400px;
  /* movie_camera */
}

.ap-dress {
  background-position: -400px -200px;
  /* bomb */
}

.ap-kimono {
  background-position: -400px -160px;
  /* bulb */
}

.ap-bikini {
  background-position: -400px -560px;
  /* moneybag */
}

.ap-womans_clothes {
  background-position: -160px -480px;
  /* ribbon */
}

.ap-purse {
  background-position: -160px -760px;
  /* tada */
}

.ap-handbag {
  background-position: -160px -500px;
  /* gift */
}

.ap-pouch {
  background-position: -160px -740px;
  /* balloon */
}

.ap-school_satchel {
  background-position: -80px -200px;
  /* heart */
}

.ap-mans_shoe {
  background-position: -420px -300px;
  /* pushpin */
}

.ap-athletic_shoe {
  background-position: -60px -700px;
  /* heavy_check_mark */
}

.ap-high_heel {
  background-position: -600px -380px;
  /* no_entry_sign */
}

.ap-high_heel {
  background-position: -560px -400px;
  /* see_no_evil */
}

.ap-sandal {
  background-position: -560px -420px;
  /* hear_no_evil */
}

.ap-boot {
  background-position: -560px -440px;
  /* speak_no_evil */
}

.ap-crown {
  background-position: -420px -580px;
  /* books */
}

.ap-womans_hat {
  background-position: -460px -260px;
  /* top */
}

.ap-tophat {
  background-position: -460px -240px;
  /* soon */
}
</style>