import Sidebar from './SideBar.vue';
import SidebarItem from './SidebarItem.vue';


const state = {
  isSidebarVisible: true,
  isMinimized: false,
  breakpoint: 1200
}

const getters = {
  isSidebarVisible: state => state.isSidebarVisible,
  isMinimized: state => state.isMinimized,
  displaySidebar(state) {
    if (window.innerWidth > state.breakpoint) {
      return;
    }
    // this.showSidebar = state.isSidebarVisible;
    let docClasses = document.body.classList
    if (value) {
      docClasses.add('g-sidenav-pinned')
      docClasses.add('g-sidenav-show')
      docClasses.remove('g-sidenav-hidden')
    } else {
      docClasses.add('g-sidenav-hidden')
      docClasses.remove('g-sidenav-pinned')
    }
  },
}

const mutations = {
  toggleSidebar (state) {
      state.isSidebarVisible = !state.isSidebarVisible
  },
  minimizeSidebar (state) {
    state.isMinimized = !state.isMinimized
  }
}

const actions = {
  toggleSidebar ({ commit }) {
      commit('toggleSidebar')
  },
  minimizeSidebar ({ commit }) {
    commit('minimizeSidebar')
  }
}

const SidebarStore = {
  showSidebar: true,
  sidebarLinks: [],
  isMinimized: false,
  isVisible: false,
  breakpoint: 1200,
  displaySidebar(value) {
    if (window.innerWidth > this.breakpoint) {
      return;
    }
    this.showSidebar = value;
    let docClasses = document.body.classList
    if (value) {
      docClasses.add('g-sidenav-pinned')
      docClasses.add('g-sidenav-show')
      docClasses.remove('g-sidenav-hidden')
    } else {
      docClasses.add('g-sidenav-hidden')
      docClasses.remove('g-sidenav-pinned')
    }
  },
  toggleMinimize() {
    this.isMinimized = !this.isMinimized;
    let docClasses = document.body.classList
    if (this.isMinimized) {
      docClasses.add('g-sidenav-hidden')
      docClasses.remove('g-sidenav-pinned')
    } else {
      docClasses.add('g-sidenav-pinned')
      docClasses.remove('g-sidenav-hidden')
    }
  },
  onMouseEnter() {
    if (this.isMinimized) {
      document.body.classList.add('g-sidenav-show')
      document.body.classList.remove('g-sidenav-hidden')
      this.isVisible = true
    }
  },
  onMouseLeave() {
    if (this.isMinimized) {
      let docClasses = document.body.classList
      docClasses.remove('g-sidenav-show')
      docClasses.add('g-sidenav-hide')
      this.isVisible = false
      setTimeout(() => {
        docClasses.remove('g-sidenav-hide')
        docClasses.add('g-sidenav-hidden')
      }, 300)
    }
  }
};

const SidebarPlugin = {
  install(Vue, { store, sidebarLinks }) {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }

    store.registerModule('sidebar', { state, mutations, actions, getters })

    if (sidebarLinks) {
      SidebarStore.sidebarLinks = sidebarLinks;
    }
    let app = new Vue({
      data: {
        sidebarStore: SidebarStore
      }
    });
    Vue.prototype.$sidebar = app.sidebarStore;
    Vue.component('side-bar', Sidebar);
    Vue.component('sidebar-item', SidebarItem);
  }
};

export default SidebarPlugin;
