<template>
  <router-link class="apaSidebarLink" @click.native="onClick" :target="target" :to="path">
    <div class="apaSidebarLink--icon">
      <i :class="icon" />
    </div>
    <div class="apaSidebarLink--text">{{ name }}</div>
  </router-link>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "apa-sidebar-link",
  props: {
    icon: { type: String },
    name: { type: String },
    path: { type: String },
    target: { type: String }
  },
  computed: {
    isActive() {
      if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find(c =>
          this.$route.path.startsWith(c.link.path)
        );
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    onClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
