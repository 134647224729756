<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="$route.meta.navbarClasses ? $route.meta.navbarClasses : 'bg-white navbar-light'"
    type
  >
    <search-field />

    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-sm-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler" @click="onSidebarClick">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
    </ul>
    <img :src="require('@/assets/images/apa-logo.png')" class="navbar-brand-img" alt="APA" />
  </base-nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import SearchField from '@/components/SearchField';
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CollapseTransition,
    SearchField
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
    };
  },
  methods: {
    ...mapActions(["setSidebarVisible"]),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    onSidebarClick() {
      this.setSidebarVisible();
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
