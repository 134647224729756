import Vue from 'vue'
import ElementUI from 'element-ui'
import Avatar from  '@/components/Avatar/Avatar.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import WidgetCard from '@/components/Cards/WidgetCard.vue';

import Modal from '@/components/Modal.vue';
import StatsCard from '@/components/Cards/StatsCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseSplitButton from '@/components/BaseSplitButton.vue';

import DotBadge from '@/components/Badge.vue';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseRadio from '@/components/Inputs/BaseRadio';
import BaseProgress from '@/components/BaseProgress';
import BasePagination from '@/components/BasePagination';
import BaseAlert from '@/components/BaseAlert';
import BaseNav from '@/components/Navbar/BaseNav';
import BaseHeader from '@/components/BaseHeader';
import FileIcon from '@/components/FileIcon/FileIcon';
import flatPicker from 'vue-flatpickr-component';
import { German } from 'flatpickr/dist/l10n/de.js';
import VueQuillEditor from 'vue-quill-editor';
import CompactModeToggle from '@/components/CompactModeToggle.vue';

import 'flatpickr/dist/flatpickr.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import i18n from '../i18n';
// Konva

import VueKonva from 'vue-konva';
import AsyncComputed from 'vue-async-computed';
import VueGoodTablePlugin from 'vue-good-table';


/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
Vue.prototype.$notify = ElementUI.Notification;
flatpickr.localize(German);

const GlobalComponents = {
  install(Vue) {
    Vue.use(ElementUI, {
      i18n: (key, value) => i18n.t(key, value)
    })
    Vue.component('Avatar', Avatar)

    Vue.component(DotBadge.name, DotBadge)
    Vue.component(BaseAlert.name, BaseAlert)
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseHeader.name, BaseHeader)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(FileIcon.name, FileIcon)
    Vue.component(BasePagination.name, BasePagination)
    Vue.component(BaseProgress.name, BaseProgress)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseSwitch.name, BaseSwitch)
    Vue.component(Card.name, Card)
    Vue.component(WidgetCard.name, WidgetCard)
    Vue.component(Modal.name, Modal)
    Vue.component(StatsCard.name, StatsCard)
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb)
    Vue.component(BaseSplitButton.name, BaseSplitButton)
    Vue.component('CompactModeToggle', CompactModeToggle)
    Vue.component('FlatPicker', flatPicker),
    Vue.use(VueKonva)
    Vue.use(AsyncComputed)

    Vue.use(VueQuillEditor)
    Vue.use(VueGoodTablePlugin);

  }
}

export default GlobalComponents
