<template>
  <li class="list-group-item px-0">
    <div class="checklist-item" :class="{
      'checklist-item-checked': isDone,
      'checklist-item-danger': isImportant
    }">

      <div class="checklist-info-wrapper flex-grow-1" @click="navigateToSubTask()">
        <base-checkbox :checked="isDone" :disabled="task.children && task.children.length > 0 && hasOpenChildTasks"
          @cclick="onClick" v-if="!isChild && !isCard && !task.interval" />

        <el-tooltip v-if="task.interval" :content="$t('task.'+task.interval)" placement="top">
          <i class="fas fa-history pr-1 m-auto text-primary" v-if="task.interval"></i>
        </el-tooltip>

        <div class="checklist-avatar checklist-info mr-2" v-if="showAvatar" v-bind:class="{ 'ml-3 mt-2': isCard }">
          <avatar :user-id="task.user.id"></avatar>
        </div>

        <div class="checklist-title-wrap checklist-info flex-grow-1">
          <h5 class="checklist-title mb-0 " :class="{ 'clickable': !isDashboard && !isChild, 'mt-2': isCard }"
            @click="onEdit(task)">
            <span v-if="task.projectId">[{{ task.projectId }}]</span>
            {{ task.title }}
            <!--<i class="fas fa-star ml-1 active-star" @click="toggleFav(task)" v-if="isFav"></i>-->
          </h5>

          <small v-if="task.milestone && !isCard">{{ task.milestone.name }}</small>

          <div>
            <template v-if="(task.subtasks && task.subtasks.length > 0) || hasNote">
              <el-tooltip :content="$t('task.preview')" placement="top">
                <i class="fas fa-angle-down mr-1" @click="previewVisible = !previewVisible"></i>
              </el-tooltip>
            </template>
          </div>

        </div>
      </div>

      <div class="checklist-meta text-right" v-if="fixedProjectId">
        <div v-if="daysDiff(task) > 0">
          <flat-picker :config="dateConfig" class="taskdate" @input="onInput"
            v-model="task.due">
          </flat-picker>
        </div>
        <div v-if="daysDiff(task) <= 0">
          <flat-picker :config="dateConfig" class="taskdate text-red" @input="onInput" v-model="task.due">
          </flat-picker>
        </div>
        <div v-if="task.createdBy"><small>von: {{ task.createdBy.name }}</small></div>

        <div class="d-flex flex-row mb-0 mt-1 checklist-hints">
          <div>
            <template v-if="task.subtasks && task.subtasks.length > 0">
            <i class="fas fa-list text-600 mr-1 float-left"></i>
              <el-tooltip
                :content="$t('task.checklist.tooltip', { value: task.subtasks.filter(st => st.isDone).length, of: task.subtasks.length })"
                placement="top">
                <div class="progress">
                  <div class="progress-bar " :class="'progress-bar-ok'" role="progressbar"
                    :style="{ width: ((task.subtasks.filter(st => st.isDone).length / task.subtasks.length) * 100) + '%' }">
                  </div>
                </div>
              </el-tooltip>
            </template>
          </div>
          <div>
            <template v-if="hasComments">
              <el-tooltip :content="$t('task.comments', { count: task.comments.length })" placement="top">
                <i class="fas fa-comments ml-2"></i>
              </el-tooltip>
            </template>
          </div>
          <div>
            <template v-if="task.has_attachment > 0">
              <el-tooltip :content="$t('task.attachment')" placement="top">
                <i class="fas fa-paperclip text-600 ml-2"></i>
              </el-tooltip>
            </template>
          </div>
          <div>
            <template v-if="hasNote">
              <el-tooltip :content="$t('task.description')" placement="top">
                <i class="ni ni-collection ml-2"></i>
              </el-tooltip>
            </template>
          </div>

          <div class="toggle-fav">
            <el-tooltip :content="isFav ? $t('task.remove_fav') : $t('task.add_fav')" placement="top">
              <a @click="toggleFav(task)">
                <i class="fa fa-star ml-2" v-if="!task.interval" v-bind:class="[isFav ? 'active-star' : 'inactive-star']"></i>
              </a>
            </el-tooltip>
          </div>

        </div>
      </div>

      <div class="checklist-meta text-right" v-if="!fixedProjectId">
        <div v-if="task.project">
          <small><router-link :to="`/project/${task.project.id}`">{{ task.project.name }}</router-link></small>
        </div>
        <small v-if="task.due && daysDiff(task) > 0">{{ task.due | date }}</small>
        <small v-if="task.due && daysDiff(task) <= 0" class="text-red">{{ task.due | date }}</small>

        <div class="d-flex flex-row mb-0 mt-1 checklist-hints">
          <div>
            <template v-if="task.subtasks && task.subtasks.length > 0">
              <i class="fas fa-list text-600 mr-2 float-left"></i>
              <el-tooltip
                :content="$t('task.checklist.tooltip', { value: task.subtasks.filter(st => st.isDone).length, of: task.subtasks.length })"
                placement="top">
                <div class="progress ">
                  <div class="progress-bar " :class="'progress-bar-ok'" role="progressbar"
                    :style="{ width: ((task.subtasks.filter(st => st.isDone).length / task.subtasks.length) * 100) + '%' }">
                  </div>
                </div>
              </el-tooltip>
            </template>
          </div>
          <div>
            <template v-if="hasComments">
              <el-tooltip :content="$t('task.comments', { count: task.comments.length })" placement="top">
                <i class="fas fa-comments text-600 ml-2"></i>
              </el-tooltip>
            </template>
          </div>
          <div>
            <template v-if="task.has_attachment > 0">
              <el-tooltip :content="$t('task.attachment')" placement="top">
                <i class="fas fa-paperclip text-600 ml-2"></i>
              </el-tooltip>
            </template>
          </div>
          <div>
            <template v-if="hasNote">
              <el-tooltip :content="$t('task.description')" placement="top">
                <i class="ni ni-collection ml-2"></i>
              </el-tooltip>
            </template>
          </div>

          <div class="toggle-fav" v-if="!isChild">
            <el-tooltip :content="isFav ? $t('task.remove_fav') : $t('task.add_fav')" placement="top">
              <a @click="toggleFav(task)">
                <i class="fa fa-star ml-2" v-if="!task.interval" v-bind:class="[isFav ? 'active-star' : 'inactive-star']"></i>
              </a>
            </el-tooltip>
          </div>
        </div>
      </div>

      <el-dropdown trigger="click" class="dropdown" v-if="showTools && !isDashboard" @command="onCommand">
        <span class="btn btn-sm btn-icon-only text-light mr-2">
          <i class="fas fa-ellipsis-v mt-2"></i>
        </span>
        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
          <el-dropdown-item command="edit">{{ $t('message.edit') }}</el-dropdown-item>
          <el-dropdown-item command="copy">{{ $t('message.copy') }}</el-dropdown-item>
          <el-dropdown-item :command="isFav ? 'remove-fav' : 'add-fav'">{{ $t(`task.${isFav ? 'remove_fav' :
              'add_fav'}`)
          }}</el-dropdown-item>
          <el-dropdown-item command="addChild" v-if="!isChild">{{ $t('message.add_sub_task') }}</el-dropdown-item>
          <el-dropdown-item command="delete">{{ $t('message.delete') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="task-preview" v-if="previewVisible">
        <div v-if="hasNote">
          Notiz:
          <div v-html="task.description"></div>
        </div>
        <div v-if="task.subtasks && task.subtasks.length > 0">
          Checkliste:
          <ul v-for="(item, i) in task.subtasks" :key="i" :item="item">
            <li class="checklist-item-label">{{ item.title }}</li>
          </ul>
        </div>
    </div>

    <ul class="list-group list-group-flush list-group-sub" data-toggle="checklist">
      <task-item v-for="sub in taskChildren" @command="onChildCommand" :fixed-project-id="fixedProjectId"
        :is-child="true" :key="sub.id" :show-tools="showTools" :task="sub" :show-done-tasks="showDoneTasks"></task-item>
    </ul>
  </li>
</template>
<script>
import { TASK_CLOSED, TASK_NEW } from "@/store/modules/tasks/constants";
import moment from 'moment';
import FlatPicker from "vue-flatpickr-component";
import { mapGetters } from 'vuex';

export default {
  name: "TaskItem",
  components: {
    FlatPicker
  },
  props: {
    isCard: {
      type: Boolean,
      default: false
    },
    fixedProjectId: {
      type: Number
    },
    isChild: {
      type: Boolean,
      default: false
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    showTools: {
      type: Boolean,
      default: false
    },
    showDoneTasks: {
      type: Boolean,
      default: false
    },
    task: {
      type: Object
    },
    isFav: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateConfig: {
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d.m.Y"
      },
      previewVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    isDone: {
      get() {
        return this.task.state === TASK_CLOSED;
      }
    },
    hasOpenChildTasks() {
      return !!this.task.children.filter(t => t.state === TASK_NEW).length
    },
    isImportant() {
      return false;
    },
    taskChildren() {
      let tasks = this.task.children

      if (!this.showDoneTasks) {
        tasks = tasks.filter(t => t.state === TASK_NEW)
      }

      return tasks
    },
    hasComments() {
      return this.task.comments && this.task.comments.length > 0
    },
    hasNote() {
      return this.task.description && this.task.description !== ''
    },
    // hasCommentsWithAttachments() {
    //   if (!this.task.comments || this.task.comments.length === 0) {
    //     return false
    //   }
    //   return !!this.task.comments.filter(c => c.documents && c.documents.lenth > 0).length
    // }
  },
  methods: {
    onChildCommand(command, task, options = {}) {
      this.$emit("command", command, task, options);
    },
    onCommand(command, options = {}) {
      const { task } = this;
      this.$emit("command", command, task, options);
    },
    onEdit(item) {
      if (!this.isDashboard && !this.isChild) {
        this.onCommand("edit");
      }
      // else if (this.isChild) {
      //   const url = this.$router.resolve({ path: `/project/${this.$route.params.id}/tasks/${item.id}`})
      //   window.open(url.href, '_blank')
      // }
    },
    daysDiff(milestone, round = false) {
      const now = moment();
      const then = moment(milestone.due);
      const diff = then.diff(now, 'days', true)
      if (round) {
        return Math.round(diff)
      }
      return diff
    },
    toggleFav(task) {
      this.onCommand(this.isFav ? 'remove-fav' : 'add-fav')
    },
    navigateToSubTask() {
      if (this.isChild)
        this.$emit("showSub");
    },
    onClick(e) {
      const { task } = this;
      const newValue = e.target.checked ? TASK_CLOSED : TASK_NEW;

      if (task.user_id === this.currentUser.id) {
        task.state = newValue
        this.$emit("command", "state", task, { state: task.state });

      } else {
        e.preventDefault();

        this.$confirm(
          `Wollen Sie den Status dieser Aufgabe ändern?`,
          `Aufgabe`,
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        ).then(() => {
          task.state = newValue
          this.$emit("command", "state", task, { state: task.state });
        })
      }
    },
    onInput(value) {
      this.task.due = value;
      this.$emit("newDate", value, this.task);
    }
  }
};
</script>

<style scoped>
.checklist-title {
  line-height: 2.2em;
}

.inactive-star {
  color: #424242;
}

.checklist-meta {
  /* width: 180px; */
  padding: 0 10px;
  flex: 0 0 auto;
}

.checklist-hints {
  justify-content: flex-end;
}

.toggle-fav {
  margin-top: -1px;
  cursor: pointer;
}

.checklist-meta>* {
  position: relative;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 575px) {
  .checklist-title-wrap {
    margin-right: 40px;
  }

  .checklist-avatar {
    position: absolute;
    left: 0px;

    top: 38px;
  }
}

.fa-list{
  margin-top: 2px
}


.progress {
  width: 100px;
  margin-bottom: 0px;
  margin-top: 5px;
}
</style>
