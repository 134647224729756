import Vue from 'vue'
import router from '@/routes/router'
import store from './store/index'
import { omit } from 'lodash'

const Auth = (envLoader) => {
    const baseUrl = " = envLoader.get().rootUrl"

    const storeTokens = (response) => {
        return Promise.all([
            store.dispatch('setAuthToken', response.body.token),
            store.dispatch('setCurrentUser', omit(response.body, ['token']))
        ])
    }

    const setAuthHeader = (request) => {
        request.headers.set('Authorization', `Bearer ${store.state.auth.authToken}`)
    }

    const refreshUser = (user) => {
        store.commit('currentUser', user)
    }

    return {
        install(Vue, options) {
            Vue.http.interceptors.push((request, next) => {
                // todo: refactoring remove nested auth object
                const token = store.state ? store.state.auth.authToken : null
                const hasAuthHeader = request.headers.has('Authorization')

                if (token && !hasAuthHeader) {
                    setAuthHeader(request)
                }

                next(response => {
                    if (response.status === 401) {
                        store.dispatch('clearAuth')
                        router.push({
                            name: 'Login'
                        })
                    }

                })
            })

            Vue.prototype.$auth = Vue.auth = this
        },
        login(credentials, redirection) {
            return Vue.api.auth.login(credentials)
                .then(response => {
                    return storeTokens(response)
                        .then(() => {
                            if (redirection) {
                                router.push({
                                    path: redirection
                                })
                            }
                            return response.body
                        })

                })
        },
        logout() {
            store.commit('clearAuth')
            router.push({
                path: '/login'
            })
        }
    }
}

export default Auth
