import { TASK_NEW } from './constants';

export default function createTask(defaults = {}) {
  delete defaults.id
  delete defaults.createdBy
  delete defaults.milestone
  delete defaults.project
  delete defaults.comments

  return {
    id: null,
    client_id: 0,
    project_id: null,
    title: '',
    description: '',
    user_id: null,
    milestone_id: null,
    due: '',
    position_id: null,
    state: TASK_NEW,
    created_by: null,
    interval: null,
    interval_repeat: null,
    start_interval: null,
    end_interval: null,
    ...defaults,
  };
}
