
import EnvLoader from '@/plugins/env-loader'
import envs from '@/env'

// NextCloud Session update watchdog
export default function() {
  const seconds = 5*60;
  const ncUrl = EnvLoader.env(envs).ncUrl + '/apps/files/?dir=/&fileid=6';

  setTimeout(refresh, seconds*1000);

  function refresh() {
    fetch(ncUrl, {
      method: 'GET',
      credentials: 'include',
      mode: 'no-cors'
    }).then( () => {
      console.log("NC refresh success");
      setTimeout(refresh, seconds*1000)
    }).catch (e => {
    console.log("NC refresh error", e);
    setTimeout(refresh, seconds*1000)
    })
  }

}