<template>
  <div class="d-flex">
    <base-checkbox :checked="item.isDone === 1" @input="onInputChange"></base-checkbox>
    <div>   
      <template v-if="!isEditMode">
        <span class="checklist-item-label">{{ item.title }}</span>
      </template>
      <template v-else>
        <base-input :placeholder="$t('task.checklist.title-placeholder')" v-model="item.title" />
      </template>
    </div>
  
    <div class="ml-auto">
      <base-button type="link" size="sm" v-if="!isEditMode" @click="isEditMode = !isEditMode">
        <i class="fas fa-edit text-black"></i>
      </base-button>
      <base-button type="link" size="sm" v-else @click="onUpdate" :disabled="item.title === ''">
        <i class="fas fa-save text-black"></i>
      </base-button>
        <base-button type="link" size="sm" @click="onDelete" >
        <i class="fas fa-trash text-black"></i>
      </base-button>
    </div>

  </div>
</template>

<script>

export default {
  name: 'task.checklist-item',
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      isEditMode: false
    }
  },
  methods: {

    onInputChange (value) {
      this.$emit('update', { item: this.item, value})
    },
    onUpdate () {
      this.$emit('update', { item: this.item })
      this.isEditMode = false
    },
    onDelete () {
      this.$emit('delete', { item: this.item })
    }
  }
}
</script>
<style>
  .checklist-item-label {
    font-size: 0.875rem;
  }
</style>