<template>
  <div
    class="apaSidebar text-sm"
    :class="{ isPinned: sidebarIsPinned, isVisible: sidebarIsVisible, useTransitions }"
  >
    <notifications />
    <div class="apaSidebar--panel">
      <apa-sidebar-header :user="user" />
      <slot name="links"></slot>
    </div>
    <div class="apaSidebar--content" @mousedown="onMouseDown">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApaSidebarHeader from "./ApaSidebarHeader";

export default {
  name: "apa-sidebar",
  components: {
    ApaSidebarHeader
  },
  computed: {
    ...mapGetters(["sidebarIsPinned", "sidebarIsVisible"])
  },
  data() {
    return {
      useTransitions: false
    };
  },
  props: {
    user: {
      type: Object
    }
  },
  watch: {
    sidebarIsPinned() {
      this.enabledTransitions();
    },
    sidebarIsVisible() {
      this.enabledTransitions();
    }
  },
  methods: {
    ...mapActions(["setSidebarVisible"]),
    enabledTransitions() {
      this.useTransitions = true;
      setTimeout(() => (this.useTransitions = false), 200);
    },
    onMouseDown() {
      if (this.sidebarIsVisible) {
        this.setSidebarVisible(false);
      }
    }
  }
};
</script>
