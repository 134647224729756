<template>
  <el-select :disabled="disabled || isLoading" :multiple="multiple" :value="value" @input="onInput" filterable>
    <el-option v-if="nullable" :label="typeof nullable === 'string' ? nullable : '(No milestone)'" :value="null" />
    <el-option v-for="milestone in milestones" :key="milestone.id" :label="label(milestone)"
      :value="milestone.id"></el-option>
  </el-select>
</template>

<script>
import moment from "moment";
import { isEqual } from "lodash";

export default {
  name: "apa-milestone-select",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object
    },
    multiple: {
      type: Boolean
    },
    nullable: {
      type: [Boolean, String]
    },
    value: {
      type: [Array, Number]
    }
  },
  data() {
    return {
      isLoading: true,
      milestones: []
    };
  },
  watch: {
    filter(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      this.isLoading = true;
      const milestones = await this.$store.dispatch("findMilestones", {
        data: {
          select: ['id', 'name', 'p_address_id', 'due'],
          and: this.filter,
          with: {
            address: {
              "one": "project_address",
              "this": "p_address_id",
              "that": 'id',
              'query': {
                select: ['id', 'address_id', 'date_from', 'date_to'],
                'with': {
                  'address': {
                    'one': 'address',
                    'this': 'address_id',
                    'that': 'id',
                    query: {
                      select: ['id','name']
                    }
                  },
                }
              }
            },
          }
        },
        options: {
          commit: false
        }
      });

      this.isLoading = false;
      this.milestones = milestones.sort((left, right) =>
        left.name.localeCompare(right.name)
      );
    },
    /**
     * Label for Milestone-<select>
     *
     * @param {object} Milestone
     * @return {string} Label "name (location | due [ | days over due ])"
     */
    label(milestone) {
      let parts = [];

      if (milestone.address) {
        parts.push(milestone.address.address.name);
      }

      parts.push(
        milestone.due
          .split("-")
          .reverse()
          .join(".")
      );

      // include days over due, if surpassed
      if (this.daysDiff(milestone) <= 0) {
        parts.push(
          this.$t("message.due", { diff: this.daysDiff(milestone, true) * -1 })
        );
      }

      return `${milestone.name} (${parts.join(" | ")})`;
    },
    daysDiff(milestone, round = false) {
      const now = moment();
      const then = moment(milestone.due);
      const diff = then.diff(now, "days", true);
      if (round) {
        return Math.round(diff);
      }
      return diff;
    },
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
