import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({
  actions: {
    loadAdditional({ dispatch }, projectId) {
      return dispatch('findMaterial_additionals', {data: {
            and: { project_id: projectId },
            with: {
              ride: {
                one: 'ride',
                'this': 'ride_id',
                query: {
                  with: {
                    vehicle: {'one': 'vehicle', 'this': 'vehicle_id'}
                  }
                }
              },
              bhb_position: {'one': 'option', 'this': 'bhb_position_id'},
              category: {'one': 'option', 'this': 'category_id'},
              'user_komm_ref': {
                'one': 'users',
                'this': 'user_komm'
              },
              'user_lade_ref': {
                'one': 'users',
                'this': 'user_lade'
              },
              'user_rueck_ref': {
                'one': 'users',
                'this': 'user_rueck'
              },
              "address": {
                "one": "project_address",
                "this": "p_address_id",
                "that": 'id',
                'query': {
                  'with': {
                    'address': {
                      'one': 'address',
                      'this': 'address_id',
                      'that': 'id'
                    },
                  }
                }
              },
            },
        },
        options: {
          commit: false
        }
      }).then(additional => {
        additional.forEach(m => {
          m.pos = m
          m.additional = true
        })
        return additional
      })
    },
}
}, Base('material_additional'))
