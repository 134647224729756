<template>
  <div v-loading="isLoading">
    <h4 v-if="label">{{ label }}</h4>
    <comment-form ref="commentForm" class="mt-4 mb-4" @saved="checkSaved($event)" :at-values="atUsers"
      :createComment="onCreate" :attachmentUploaded="onUpload" :enableDrag="this.enableDrag" :triggerReload="onReload"
      :force-internal="forceInternal" />
    <div>
      <comment @emitComment="createNewFromComment" @stateChanged="stateChanged" :at-values="atUsers" :comment="comment"
        :createComment="onCreate" :triggerReload="onReload" :key="comment.id" v-for="comment in comments" />
    </div>
  </div>
</template>

<script>




import { mapGetters } from 'vuex'
import moment from "moment";

import Comment from "@/components/Feed/Comment";
import CommentForm from "@/components/Feed/CommentForm";
import createComment from '@/store/modules/comments/createComment';
import toHierarchy from '@/store/modules/comments/toHierarchy';


export default {
  name: "apa-comments",
  components: {
    Comment,
    CommentForm
  },
  props: {
    fixtures: {
      type: Object,
    },
    hideComments: {
      type: Boolean,
      default: false,
    },
    createAlsoAsDebriefing: {
      type: Boolean,
      default: false
    },
    commentTextAddon: {
      type: String
    },
    enableDrag: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
    },
    reloadComments: {
      type: Object
    }
  },
  computed: {
    ...mapGetters([
      'atUsers',
      'currentUserRights',
      'currentUser'
    ]),
    forceInternal() {
      return !this.currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Kunde bearbeiten')
    }
  },
  data() {
    return {
      createdComment: {},
      comments: [],
      isLoading: false,
    };
  },
  watch: {
    fixtures() {
      this.$refs.commentForm.resetInternal(this.forceInternal)
    },
    reloadComments() {
      this.comments = [];
      if(this.reloadComments.triggerReload) {
        this.loadComments();
      }
    }
  },
  methods: {
    onCreate: async function (comment) {
      const created = moment().format("YYYY-MM-DD HH:mm:ss");
      const { $store, fixtures } = this;
      const { id: user_id } = $store.getters.currentUser;
      const fixt = Object.assign({}, fixtures)
      //If multiple types are in the fixture to load multiple comment types the default is the first one to create new comments
      if (fixt.type) {
        if(fixt.type['in']){
          fixt.type = fixt.type['in'][0];
        }
      }
      delete fixt.id
      this.createdComment = createComment({
        ...comment,
        ...fixt,
        user_id,
        created
      })
      const result = await $store.dispatch('createComment', {
        data: createComment({
          ...comment,
          ...fixt,
          user_id,
          created
        })
      });

      if (fixtures.project_id) {
        var tempHTML = document.createElement('html');
        tempHTML.innerHTML = comment.comment;
        var hasHashMention = Array.from(tempHTML.getElementsByClassName('mention')).map((s) => s.innerText.trim()).filter((t) => t === "#debriefing").length > 0;
        if (hasHashMention || this.createAlsoAsDebriefing) {
          fixt.type = 'DEBRIEFING';
          if (this.createAlsoAsDebriefing) {
            comment.comment = this.commentTextAddon + comment.comment;
          }
          this.$emit('createdDebriefing')
          const debriefingComment = await $store.dispatch('createComment', {
            data: createComment({
              ...comment,
              ...fixt,
              user_id,
              created
            })
          });
        }
      }

      return result;
    },

    createNewFromComment(text, thisComment) {
      this.$emit('emitComment', text, thisComment);
    },
    stateChanged(newState) {
      this.$emit('stateChanged', newState);
    },
    checkSaved(commentId) {
      this.$emit('createdObject', this.createdComment);
      this.$emit('created', commentId);
    },
    onUpload: async function () {
      this.$emit('attachmentUploaded', true)
      await this.loadComments();
      await this.onReload();
    },
    onReload: async function () {
      await this.loadComments()
    },
    loadComments: async function () {
      if (this.hideComments) {
        this.isLoading = false;
        return;
      }
      if(this.currentUser.role_id == 13 || this.currentUser.role_id == 14){
        this.fixtures.internal = 0;
      }
      this.isLoading = true;
      const comments = await this.$store.dispatch('loadComments', {
        filter: this.fixtures,
      });

      this.isLoading = false;
      this.comments = toHierarchy(comments);
    }
  },
  mounted() {
    this.loadComments();
  }
};
</script>

