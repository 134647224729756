
import store from '@/store'


export default function(action, options = {}) {
  const query = {}
  query.options = options

  function data() {
    if (!query.data) {
      query.data = {
        and: {},
        with: {},
        join: {}
      }
    }
    return query.data;
  }

  return {
    and(field, value) {
      data().and[field] = value;
      return this;
    },
    with(ref, option=null) {
      data().with[ref] = option || {one: ref, 'this': ref + '_id'}
      return this;
    },
    join(ref) {
      data().join[ref] = {'this': ref + '_id', 'that': 'id'}
      return this;
    },
    id(id) {
      query.id = id;
      return this;
    },
    run() {
      return store.dispatch(action, query)
    }
  }
}