import Vue from 'vue'
import EnvLoader from './plugins/env-loader'
import GlobalComponents from './plugins/globalComponents';
import DashboardPlugin from './plugins/dashboard-plugin'

import App from './App.vue'
import VueProgressBar from 'vue-progressbar'
import store from './store'
import i18n from './i18n'
import VueResource from 'vue-resource'
import ApaAPI from 'apa-api'
import envs from './env'
import { Modal, BaseAlert, BaseNav, WidgetCard } from '@/components'
import BaseDashboard from '@/views/Dashboard/BaseDashboard.vue'
import CustomizableDashboard from '@/views/Dashboard/CustomizableDashboard.vue'
import router from './routes/router';
import Auth from './auth'
import Vuelidate from 'vuelidate'
import Filter from './util/filters'
// router setup

// plugin setup
const auth = Auth(EnvLoader);
const loaderOptions = {
  color: '#9f2f37',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueResource);
Vue.use(ApaAPI);
Vue.use(auth);
Vue.use(EnvLoader, { envs });
Vue.use(GlobalComponents)
Vue.use(VueProgressBar,loaderOptions)
Vue.use(DashboardPlugin, { store });
Vue.use(Vuelidate);
Vue.http.options.root = EnvLoader.env(envs).rootUrl;


Vue.component('modal', Modal);
Vue.component('base-alert', BaseAlert);
Vue.component('base-nav', BaseNav);
Vue.component(BaseDashboard.name, BaseDashboard);
Vue.component(CustomizableDashboard.name, CustomizableDashboard);

var vm = new Vue({
  i18n,
  store,
  el: '#app',
  render: h => h(App),
  router
});

// Vue.http.interceptors.push((request, next) => {
//   vm.$Progress.start();
//   next((response) => {
//       vm.$Progress.finish();
//       if (response.status > 400) {
//         console.log("HTTP ERROR")
//       }
//   });
// });

