import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({
}, Base('project_tool'))

// {
//   description:	
//   Einsatzorte eines Projektes.
  
//   id	integer
//   Unique id of the item.
  
//   client_id	integer
//   Client the item lives in.
  
//   project_id	integer
//   Das Projekt.
  
//   address_id	integer
//   Der Ort.
  
//   }