import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import api from '@/util/api.js'

export default merge({
  actions: {
    loadRidesForProject({ commit, dispatch }, projectId) {
      api('findRides')
        .and('project_id', projectId)
        .with('vehicle')
        .run()
        .then(items => {
          commit('rides', items)
          return items
        })
    }
  }
}, Base('ride'))
