import Vue from 'vue'
import store from './store'
import VueI18n from 'vue-i18n'
import messages from './lang'
import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'de',// store.getters.currentLocale, // set locale
	messages, // set locale messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))


export default i18n