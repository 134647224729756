<template>
  <li class="breadcrumb-item" :class="{ active: active }">
    <slot>
      <template v-if="route">
        <router-link
        v-if="index < $route.matched.length - 1"
        :to="{ name: route.name }">
      
   <template v-if="route.meta.bcDynamic">
             <template v-if="value">
                {{formattedValue}}
              </template>
              <template v-if="!value">
                {{loadingText}}
              </template>
          </template>
          <template v-else>
            {{ $t('message.breadcrumb.' + route.name) }}
          </template>
        </router-link>
        <span v-else>
          <template v-if="route.meta.bcDynamic">
             <template v-if="value">
                {{formattedValue}}  
              </template>
              <template v-if="!value">
                {{loadingText}}
              </template>
          </template>
          <template v-else>
            {{ $t('message.breadcrumb.' + route.name) }}
          </template>
        </span>
      </template>
      
    </slot>
  </li>
</template>
<script>
export default {
  name: 'breadcrumb-item',
  props: ['route','active', 'index'],
  beforeCreate() {
    this.$options.computed.value = function() {
      if (this.route.meta.bcGetter) {
        return this.$store.getters[this.route.meta.bcGetter] 
      } else {
        return null
      }
    }
  },
  computed: {
    formattedValue() { return this.route.meta.bcLinkText(this.value) },
    loadingText() {
      const loadingText = this.route.meta.bcLoadingText
      return loadingText ? loadingText : 'Loading...'
    }
  },
};
</script>
<style></style>
