<template>
  <div class="d-md-flex" v-loading="isLoading">
    <div style="flex:1;">
      <social-input :placeholder="placeholder" v-model="comment" :at-values="atValues"
        :hashValues="[{ id: 1, value: 'debriefing' }]"></social-input>
      <base-checkbox v-model="isInternalComment" :disabled="forceInternal === 1"
        v-if="currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Intern sehen') && currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Kunde sehen')">{{
          $t('message.internal_comment')
        }}</base-checkbox>
    </div>
    <div class="pl-md-3">

      <el-upload ref="upload" v-if="enableDrag" action="#" drag multiple :before-remove="onFilesBeforeRemove"
        :http-request="setDocument" :on-change="onFileChange" :limit="5"> {{ $t('message.upload_files') }}
      </el-upload>

    </div>
    <div class="pl-md-3">

      <base-button type="primary" class="mb-2 sendComment" @click="onSaveClick"
        :disabled="comment === '' && fileList.length === 0">
        <i class="far fa-paper-plane"></i>
      </base-button>
      <el-upload ref="upload" v-if="!this.enableDrag" action="#" multiple :before-remove="onFilesBeforeRemove"
        :http-request="setDocument" :on-change="onFileChange" :limit="5">
        <base-button type="default">
          <i class="fas fa-paperclip"></i>
        </base-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SocialInput from "./SocialInput";

export default {
  name: "comment",
  components: {
    SocialInput

  },
  props: {
    text: {
      type: String,
      default: ''
    },
    atValues: {
      type: Array
    },
    createComment: {
      type: Function
    },
    attachmentUploaded: {
      type: Function
    },
    triggerReload: {
      type: Function
    },
    placeholder: {
      type: String,
      default: "Comment"
    },
    forceInternal: {
      type: Boolean,
      default: 0
    },
    enableDrag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      comment: "",
      fileList: [],
      isInternalComment: true,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters([
      'project',
      "currentUserRights"
    ])
  },
  watch: {
    forceInternal(val) {

    }
  },
  mounted() {
    this.comment = this.text
    this.resetInternal()
  },
  methods: {
    ...mapActions([
      'loadProjectDocs'
    ]),
    onFilesBeforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    onFilesExceed(files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      );
    },
    onFileChange(res, files) {
      this.avatar = {
        url: URL.createObjectURL(files[0].raw)
      }
    },
    setDocument(data) {
      this.fileList.push(data.file);
      return true
    },
    uploadFile(id) {
      if (this.fileList) {
        const files = {
          'comment_attachment[]': this.fileList
        };
        return this.$store.dispatch('uploadDocument', { type: 'comment_attachment', id, files })
      }
    },
    async onSaveClick() {
      this.isLoading = true;
      try {
        if (this.comment === '' && this.fileList.length > 0) {
          this.comment = 'Anhang'
        }
        const { id } = await this.createComment({
          comment: this.comment,
          internal: this.isInternalComment
        });
        await this.uploadFile(id);
        await this.attachmentUploaded();
        this.comment = "";

        // this.isInternalComment = false;
        //if (this.fileList) {
        //this.loadProjetDocs(this.project.id)
        //}

        this.fileList = []
        this.$refs.upload.clearFiles()
        this.$emit('saved', id)
        this.triggerReload()
      } catch (error) {
        this.$message.error(error);
      }

      this.isLoading = false;
    },
    resetInternal() {
      if (this.forceInternal == true) {
        this.isInternalComment = true
      } else if (!this.currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Intern sehen')) {
        this.isInternalComment = false
      } else {
        this.isInternalComment = true
      }
    }
  }
};
</script>
<style>
.el-upload-dragger {
  height: 130px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.el-message-box__wrapper {
  z-index: 1000001 !important;
}

@media (max-width: 767px) {

  .el-upload,
  .el-upload-dragger {
    width: 100%;
  }

  .sendComment {
    margin-top: 20px;
  }
}
</style>