<template>
  <div class="navbar-search form-inline mr-sm-3"
    :class="$route.meta.searchClasses ? $route.meta.searchClasses : 'navbar-search-light'" id="navbar-search-main">
    <div class="form-group mb-0">
      <div class="input-group input-group-alternative input-group-merge" v-click-outside="() => showResults = false">
        <input type="text" placeholder="Search..." v-model="searchText" size="mini" class="form-control" v-on:keyup.enter.prevent="startSearch"  />
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="fas fa-search"></i>
          </span>
        </div>
        <div class="list-group shadow list-results" v-if="showResults">
          <a v-for="item in items" class="vbst-item list-group-item list-group-item-action " @click="hit(item)">
            <span>{{ item.name }}</span>
            <small style="float: right;">{{ item.type }}</small>
          </a>
        </div>
      </div>
    </div>
    <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
</template>

<script>
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import search from '@/util/search.js'
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'search-field',
  components: {
    VueBootstrapTypeahead
  },
  computed: {
    ...mapGetters([
      "currentUser",
      'currentUserRights'
    ])
  },
  data() {
    return {
      searchText: "",
      showResults: false,
      items: []
    };
  },
  watch: {
    searchText: _.debounce(function (search) { this.getItems(search) }, 500)
  },
  methods: {
    async getItems(s) {
      if (s.length < 3) {
        this.showResults = false;
        return;
      }
      const items = await search(s, 0, 100000)
      this.items = items.filter(i => {
        if (i.type === 'task' && !this.currentUserRights.includes('Dashboard Projekt A_ToDo´s (projektbezogen)')) {
          return false
        }
        return true
      })
      this.showResults = true;
    },
    hit(item) {
      if (item.ctx) {
        this.$store.commit('searchCtx', item.ctx);
      }
      this.$router.push({ path: item.route, params: item.ctx });
      this.searchText = '';
      this.showResults = false;
    },
    startSearch() {
      if(this.searchText.length > 0)
        this.$router.push({ path: `/search/${this.searchText}` });
    }
  },
  mounted() {
    const self = this;
    // What an ugly hack!!!
    this.$refs.type.$refs.input.onkeyup = function (event) {
      if (event.keyCode === 13) {
        self.startSearch()
      }

    }
  }
};
</script>
<style>
.list-results {
  top: 50px;
  max-width: 450px;
  position: absolute;
  max-height: 200px;
  z-index: 99;
  overflow: auto;
}

.list-results>a:hover {
  cursor: pointer;
}
</style>
