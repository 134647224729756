export default [
  {
    id: 1,
    email: 'pajic@apa.de',
    subject: 'Projekt DFB Pokalfinale 2019',
    text: 'Beim DFB Pokalfinale sind alle Banden 10cm höher als bei anderen Veranstaltungen.',
    date: '07.07.2019',
    comments:  [
      {
        id: "c-0001",
        user: {
          name: "Karmen Pajic"
        },
        date: "07.07.2019 10:15",
        text: "Und 5cm breiter."
      }
    ]
  },
  {
    id: 2,
    email: 'nickel@apa.de',
    subject: 'Frauenfußball WM 2019',
    text: 'Alle Kommissionierungen für die Frauenfußball WM 2019 1 Woche früher planen.',
    date: '06.07.2019',
    comments:  [
      {
        id: "c-0002",
        user: {
          name: "Karmen Pajic"
        },
        date: "07.07.2019 10:15",
        text: "Außerdem dürfen die LKWs nur 90% beladen sein."
      }
    ]
  },
  {
    id: 3,
    email: 'kunitz@apa.de',
    subject: 'Mitteilungen über Lieferschwierigkeiten',
    text: 'Bitte bei Lieferengpässen künftig früher Bescheid geben, damit keine Terminschwierigkeiten aufkommen.',
    date: '11.07.2019'
  }
]