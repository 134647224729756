<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <hr class="my-4" />

      <h4>{{ $t('task.checklist.title') }}</h4>
      <div class="d-flex align-items-center mt-3 mb-4">
        <div class="pr-2">{{ progress }}%</div>
        <div class="progress">
          <div class="progress-bar" :class="{ 'bg-success': progress === 100 }" role="progressbar" aria-valuenow="0"
            aria-valuemin="0" aria-valuemax="100" :style="`width: ${progress}%`"></div>
        </div>
      </div>
      <template v-if="items && items.length > 0">
        <checklist-item v-for="(item, i) in items" :key="i" :item="item" @update="onItemValueUpdate"
          @delete="onItemDelete" />
      </template>
      <div v-if="setDisabled" class="invalid-feedback d-block">Bitte zuerst die Aufgabe speichern.</div>
      <base-input :placeholder="$t('task.checklist.title-placeholder')" :disabled="setDisabled" v-model="title"
        v-on:keyup.enter="save" class="add-checklist-item mt-4"></base-input>
      <base-button @click="save()" type="primary" size="sm" :disabled="setDisabled" class="mt-3">{{
          $t('task.checklist.add')
      }}</base-button>
      <hr class="my-4" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ChecklistItem from './ChecklistItem'

export default {
  name: 'task.checklist',
  components: {
    ChecklistItem
  },
  props: {
    taskId: {
      type: Number
    },
    items: {
      type: Array,
    }
  },
  computed: {
    progress() {
      if (this.items.length > 0) {
        const percentage = 100 / this.items.length
        return Math.ceil(100 / this.items.length * this.items.filter(i => i.isDone).length)
      }
      return 0
    },
    setDisabled() {
      return false;
      // return this.taskId === null ? true : false;
    }
  },
  data() {
    return {
      addMode: false,
      isLoading: false,
      title: '',
    }
  },
  methods: {
    ...mapActions(['saveSubtask']),

    save() {
      const { taskId, title } = this

      const data = {
        task_id: this.taskId ? taskId : null,
        title,
        isDone: false
      }
      if (this.taskId !== null) {

        let p = Promise.resolve()
        if (title !== '') {
          this.isLoading = true
          p = this.$store.dispatch('createSubtask', { data });
        }

        p.then(({ id }) => {
          this.isLoading = false
          this.toggleAddMode()
          this.$emit('create', { id, ...data })
        })
      }
      else {
        if (title !== '') {
          this.$emit('create', { ...data })
          this.toggleAddMode();
        }
      }
    },
    onItemValueUpdate({ item, value }) {
      if (this.taskId === null)
        return;
      if (value) {
        item.isDone = value
      }

      this.isLoading = true

      this.$store.dispatch('updateSubtask', { id: item.id, data: { isDone: value, title: item.title } })
        .then(() => {
          this.isLoading = false
          this.$emit('update', { id: item.id, ...item })
        })
    },
    onItemDelete({ item }) {
      if (this.taskId === null) {
        const deleteIndex = this.items.findIndex((v) => v === item);
        this.$emit('delete', { id: deleteIndex })
        return;
      }

      this.isLoading = true

      this.$store.dispatch('deleteSubtask', { id: item.id })
        .then(() => {
          this.isLoading = false
          this.$emit('delete', { id: item.id })
        })
    },
    toggleAddMode() {
      this.reset()
      this.addMode = !this.addMode
    },
    reset() {
      this.title = ''
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.progress {
  width: 100%;
  margin-bottom: 0px;
}
</style>