export default (positions, groupedVariants = ['0035', '0006', '0007', '0027', '0034', '0044', '0061', '0074', '0082']) => {
  return positions.reduce((acc, c) => {
    if (groupedVariants.includes(c.mat_no)) {
      const firstItem = acc.find(p => p.mat_no === c.mat_no && p.VariantCode === c.VariantCode && p.pos.ride_id === c.pos.ride_id)
      c.quantity = parseInt(c.quantity)
      
      if (c.width && c.height) {
        c.extent = parseInt(c.quantity) * (2 * c.width + 2 * c.height) / 1000.0
      }

      if (!firstItem) {
        c.ids = [c.id]
        acc.push(c)
      } else {
        // calc
        firstItem.ids.push(c.id)
        if (c.width && c.height) {
          firstItem.extent = firstItem.extent ? firstItem.extent + c.extent : c.extent
          firstItem.width = null
          firstItem.height = null
        } else {
          firstItem.quantity += c.quantity
        }
      }
    } else {
      // fällt nicht in gruppierung
      acc.push(c)
    }
    return acc
  }, [])
}
