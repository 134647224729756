<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Default</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <slot name="menu" />
          <!-- <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button> -->
        </div>
      </div>
     
    </base-header>
    <slot />
  </div>
</template>
<script>
  import  ProjectTable from '@/components/ProjectTable'
  import  FavProjectTable from '@/components/FavProjectTable'

  import projects from '@/data/projects'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import VueGridLayout from 'vue-grid-layout'

  export default {
    name: 'BaseDashboard',
    components: {
      ProjectTable,
      FavProjectTable,
      RouteBreadCrumb,
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem
    },
    computed: {
      favoriteProjects () {
        return this.data.projects.filter(item => item.isFavorite === true)
      }
    },
    data() {
      return {
        data: {
          projects
        }
      };
    },
    methods: {

    },
    mounted() {
    }
  };
</script>
<style></style>
