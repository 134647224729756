import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import api from '@/util/api.js'

export default merge({
    actions: {
        loadPtMatchingByType({ commit }, matching_type) {
        return api('findPt_options_matchings')
                .and('option_type', matching_type)
                .run()
                .then(items => {
                    commit('pt_options_matchings', items)
                    return items
                })
        }
    }
}, Base('pt_options_matching'))
