<template>
  <div class="apaSidebarHeader">
    <div class="apaSidebarHeader--user dropdown" role="button" ref="button" tabindex="0">
      <div class="apaSidebarHeader--userImage">
        <!-- <img :src="user.avatar" v-if="user.avatar"/> -->
        <div class="avatar-circle-sm">
          <img class="rounded-circle" :src="user.avatar.url" v-if="user.avatar"/>
          <span class="initials"  v-else>{{user.name.substr(0,1).toUpperCase()}}</span>
        </div>
        <!-- <span class="initials" v-else></span> -->
      </div>
      <!-- <span class="apaSidebarHeader--userName">{{user.name}}</span> -->

      <div class="apaSidebarHeader--userDropDown dropdown-menu">
        <a @click="onSettingsClick" class="dropdown-item">
          <i class="ni ni-settings-gear-65"></i>
          <span>{{$t('message.settings')}}</span>
        </a>
        <div class="dropdown-divider"></div>
        <a @click="onLogoutClick()" class="dropdown-item">
          <i class="ni ni-user-run"></i>
          <span>{{$t('message.logout')}}</span>
        </a>
      </div>
    </div>

    <div
      class="apaSidebarHeader--menu sidenav-toggler sidenav-toggler-dark d-none d-xl-block"
      :class="{'active': !sidebarIsPinned }"
      @click="onMenuClick"
    >
      <div class="sidenav-toggler-inner">
        <i class="sidenav-toggler-line"></i>
        <i class="sidenav-toggler-line"></i>
        <i class="sidenav-toggler-line"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "apa-sidebar-header",
  props: {
    user: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["sidebarIsPinned"])
  },
  methods: {
    ...mapActions(["setSidebarPinned"]),
    onLogoutClick() {
      this.$auth.logout();
      this.$refs.button.blur();
    },
    onMenuClick() {
      this.setSidebarPinned();
    },
    onSettingsClick() {
      this.$router.push({ path: "/settings" });
      this.$refs.button.blur();
    }
  }
};
</script>


<style>

.apaSidebarHeader--menu.sidenav-toggler {
  display: none !important;
}

</style>