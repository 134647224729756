import Vue from 'vue'
import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({
  state: {
    docs: [],
  },
  getters: {
    docs: state => state.docs,
  },
  mutations: {
    docs: (state, docs) => state.docs = docs,
  },
  actions: {
    uploadDocument({ }, { type, id, files, api = false }) {
      var data = new FormData();
      for (const key in files) {
        for (const file of files[key]) {
          data.append(key, file, file.name)
        }
      }
      if (api) {
        return Vue.http.post(`${type}/${id}/documents`, data)
      } else {
        return Vue.http.post(`document/${type}/${id}`, data)
      }
    },
    getDocs({ }, { type, id, api = false }) {
      if (api) {
        return Vue.http.get(`${type}/${id}/documents`)
          .then(res => res.body)
      } else {
        let url = `document/${type}/${id}`;
        return Vue.http.get(url)
          .then(res => res.body)
      }
    },
    deleteDoc({ }, doc) {
      let url = `document/${doc.type}/${doc.id}/${doc.key}/${doc.doc}`;
      return Vue.http.delete(url)
        .then(res => res.body)
    },
    deleteDocApi({ }, docs) {
      let main = docs.docs[0]
      let url = `${main.type}/${main.item_id}/documents/` + docs.docs.map(d => d.id).join(',');
      return Vue.http.delete(url)
        .then(res => res.body)
    },
    loadProjectDocs({ commit }, id) {
      //            return Vue.http.get(`${type}/${id}/documents`)
      const type = 'project';
      let url = `document/${type}/${id}`;
      return Vue.http.get(url)
        .then(res => {
          commit('docs', res.body);
          return res.body
        })
    },
  }
}, Base('document'))


/**
 * id	integer
Unique id of the item.

client_id	integer
Client the item lives in.

type	string
The type if the item.

item_id	integer
The id of the item the document is attached to.

path	string
Path of the document below the item

name	string
Stored name of the document

mimetype	string
Mimetype as seen on upload

size	integer
Size in bytes as seen on upload

original	string
Original filename */
