
const ncDevApi = 'https://picsapa.codelightapp.de/nextcloud/index.php';

const dev = {
    rootUrl: 'https://picsapa.codelightapp.de/api/v1.0/',
    ncUrl: ncDevApi,
    debug: true
};

const localDev = {
    rootUrl: 'http://localhost/apa-backend/backend/public/api/v1.0/',
    ncUrl: ncDevApi,
    debug: true
};
const backendDev = {
    rootUrl: 'http://localhost/apa-backend/backend/public/api/v1.0/',
    ncUrl: ncDevApi,
    debug: true
};

const production = {
    rootUrl: 'https://pics.apa.de/api/v1.0/',
    ncUrl: 'https://pics.apa.de/nextcloud/index.php',
};

const test = {
    rootUrl: 'https://picsapa.codelightapp.de/api/v1.0/',
    ncUrl: ncDevApi,
};

export default {
    'development.127.0.0.1': localDev,
    'development.localhost': dev, 
    'production.picsapa.codelightapp.de': test,
    'production.picsapa-dev.codelightapp.de': dev,
    'production.pics.apa.de': production,
    'development.192.168.178.28': dev,

    // JVI: Kann der Rest hier weg?
    'development.192.168.10.172': dev,
    'production.127.0.0.1': backendDev,
    'production.18.218.132.85': production,
    'production.ec2-18-218-132-85.us-east-2.compute.amazonaws.com': production,
    'production.pensive-roentgen-c2430a.netlify.app': dev,
    'production': production
}