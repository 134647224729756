// mport api from 'api-client'
import items from './infopool.js'

const state = {
    infoPoolItems: []
}
const getters = {
    infoPoolItems: state => state.infoPoolItems
}

const mutations = {
    setInfoPoolItems (state, infoPoolItems) {
        state.infoPoolItems = infoPoolItems
    },
    addInfoPoolItem (state, infoPoolItem) {
        state.infoPoolItems.push(infoPoolItem)
    }
}

const actions = {
    loadProjects ({ commit }) {
        commit('setInfoPoolItems', items)
    },
    addInfoPoolItem ({ commit }, addInfoPoolItem) {
        commit('addInfoPoolItem', addInfoPoolItem)
    }
}

export default {
    state, getters, mutations, actions
}
