import Vue from 'vue';
import Vuex from 'vuex';

// modules
import projectAddress from './modules/project_address';
import projectPerson from './modules/project_persons';
import personAddress from './modules/person_address';
import projectTool from './modules/project_tool';
import projectOrder from './modules/project_order';
import projectPartMappings from './modules/project_part_mappings';

import address from './modules/address';
import articles from './modules/articles';
import variant from './modules/variant';

import positions from './modules/positions';

import app from './modules/app';
import auth from './modules/auth';
import document from './modules/document';
import user from './modules/user';
import projects from './modules/projects';
import milestones from './modules/milestones';
import tasks from './modules/tasks';
import subtasks from './modules/subtasks';
import task_relation from './modules/task_relation';
import task_fav from './modules/task_fav';
import task_teams from './modules/task_teams';
import roles from './modules/roles';
import news from './modules/news';
import pt_options_matching from './modules/pt_options_matching';
import vehicles from './modules/vehicles';
import infopool from './modules/infopool';
import rides from './modules/rides';
import insurances from './modules/insurances';
import comments from './modules/comments';
import customers from './modules/customers';
import order from './modules/order';
import option from './modules/option';
import notification from './modules/notification';
import material from './modules/material';
import materialAdditional from './modules/material_additional';
import debriefing from './modules/debriefing';

const storePrefix = 'apa';

Vue.use(Vuex);

// local Storage plugin
const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    const store = {
      count: 1,
      // auth: state.auth,
      user: state.user,
    };
    localStorage.setItem(storePrefix, JSON.stringify(store));
    if (mutation.type === 'clearAll') {
      localStorage.removeItem(storePrefix);
    }
  });
};

const store = new Vuex.Store({
  strict: true,
  modules: {
    auth,
    articles,
    variant,
    comments,
    customers,
    order,
    positions,
    projectAddress,
    projectPerson,
    personAddress,
    projectTool,
    projectOrder,
    projectPartMappings,
    address,
    app,
    document,
    user,
    projects,
    positions,
    milestones,
    tasks,
    task_relation,
    task_fav,
    task_teams,
    subtasks,
    roles,
    rides,
    insurances,
    news,
    pt_options_matching,
    vehicles,
    infopool,
    option,
    notification,
    material,
    materialAdditional,
    debriefing
  },
  plugins: [localStoragePlugin],
});

export function getItem(key) {
  return JSON.parse(localStorage.getItem(`apa-${key}`));
}

export function setItem(key, value) {
  return localStorage.setItem(`apa-${key}`, JSON.stringify(value));
}

export function removeItem(key) {
  return localStorage.removeItem(`apa-${key}`);
}

export default store;
