import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import api from '@/util/api.js'

export default merge({
    getters: {
        atUsers (state, rootState) {
            let result
            const mentionRoles = rootState.currentUser.role.mention_roles ? rootState.currentUser.role.mention_roles.split(',').map(r => parseInt(r)) : []
 
            if (rootState.currentUser.rights.map(r => r.actions).includes('Alle Projekte_')) {
                if (rootState.project && rootState.project.is_private) {
                    result = state.project_people
                        .map(pp => pp.user)
                } else {
                    result = rootState.users
                }
            } else {
                result = state.project_people
                    .map(pp => pp.user)
            }
            
            result = result
                .filter(user => mentionRoles.includes(user.role_id))
                .map(({ id, name }) => ({
                    id,
                    value: name
                }))

            return result
          
        }
    },
    actions: {
        async loadProjectPeople ({ commit }, projectId) {
            projectId = parseInt(projectId)
            const people = await api("findProject_people")
                .and('project_id', projectId)
                .with('user',
                {
                    one: 'users', this: 'user_id',
                    query: {
                        with: {
                            'role': {
                                'one': 'role',
                                'this': 'role_id',
                                'that': 'id'
                            }
                        }
                    }
                })
                .run()
            commit('project_people', people)
            return people
        }
    }
}, Base('project_person'))
