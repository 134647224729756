<template>
  <el-select :disabled="disabled || isLoading" :multiple="multiple" :value="value" @input="onInput" filterable>
    <el-option
      :label="$t('message.all_projects')"
      :value="-1" />
    <el-option
      v-if="nullable"
      :label="typeof nullable === 'string' ? nullable : '(No project)'"
      :value="null" />

    <el-option
      v-for="project in filteredProjects"
      :key="project.id"
      :label="project.name"
      :value="project.id"
    ></el-option>
  </el-select>
</template>

<script>
import { isEqual } from "lodash";

export default {
  name: "apa-project-select",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object
    },
    multiple: {
      type: Boolean
    },
    nullable: {
      type: [Boolean, String]
    },
    value: {
      type: [Array, Number]
    },
    validProjects: {
      type: Array,
      default: null
    },
    allProjectsSelectable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      projects: []
    };
  },
  watch: {
    filter(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.load();
      }
    }
  },
  computed: {
    filteredProjects () {
      if (this.validProjects) {
        return this.projects
          .filter(p => p.state === 'NEW')
          .filter(p => this.validProjects.includes(p.id))
      }
      return this.projects
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function() {
      this.isLoading = true;
      const projects = await this.$store.dispatch("findProjects", {
        data: {
          and: this.filter
        },
        options: {
          commit: false
        }
      });
      this.isLoading = false;
      this.projects = projects.sort((left, right) =>
        left.name.localeCompare(right.name)
      );

      
    },
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
