<template>
  <div class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light apa-red"
       @mouseenter="onMouseEnter()"
       @mouseleave="onMouseLeave()"
       :data="backgroundColor">
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <slot name="header" :isVisible="isVisible"/>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-none d-xl-block"
               :class="{'active': !isMinimized }"
               @click="minimizeSidebar" v-if="isVisible">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </ul>
        <slot name="links-after"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: 'https://demos.creative-tim.com/vue-argon-dashboard-pro/img/brand/green.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header']
    },
    ...mapGetters([
      'isMinimized'
    ])
  },
  data () {
    return {
      isVisible: true,
    }
  },
  watch: {
    isMinimized () {
      const docClasses = document.body.classList
      if (this.isMinimized) {
        docClasses.add('g-sidenav-hidden')
        docClasses.remove('g-sidenav-pinned')
      } else {
        docClasses.add('g-sidenav-pinned')
        docClasses.remove('g-sidenav-hidden')
      }
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    onMouseEnter() {
      if (this.isMinimized) {
        document.body.classList.add('g-sidenav-show')
        document.body.classList.remove('g-sidenav-hidden')
        this.isVisible = true
      }
    },
    onMouseLeave() {
      if (this.isMinimized) {
        let docClasses = document.body.classList
        docClasses.remove('g-sidenav-show')
        docClasses.add('g-sidenav-hide')
        this.isVisible = false
        setTimeout(() => {
          docClasses.remove('g-sidenav-hide')
          docClasses.add('g-sidenav-hidden')
        }, 300)
      }
    },
    minimizeSidebar() {
      this.$store.dispatch('minimizeSidebar')
      // if (this.$sidebar) {
      //   this.$sidebar.toggleMinimize();
      // }
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style>

.navbar-search .input-group {
  height: 45px;
}

.navbar-search .input-group .input-group-text {
  padding-top: 0;
}
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
