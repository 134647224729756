import { PRODUKTIONSWARE, VERBRAUCHSMATERIAL, EINKAUFSWARE, EXTERNES_LAGER, LAGER_UND_PRODUKTION, LAGERWARE, BESTANDSWARE, WERKZEUG, INHOUSE, INFOARTIKEL } from '../material/materialCategories'

export default [
  PRODUKTIONSWARE,
  LAGERWARE,
  // WERKZEUG,
  VERBRAUCHSMATERIAL,
  BESTANDSWARE,
  EINKAUFSWARE,
  EXTERNES_LAGER,
  //INHOUSE,
  INFOARTIKEL
]
