const MakeAPI = Vue => {
    if (!Vue.http) {
        throw new Error('Module "vue-resource" is missing.')
    }

    return {
        query () {
            return Vue.http.post('../../login', credentials)
        },
        getSelf () {
            return Vue.http.post('self')
        }
    }
}

export default MakeAPI