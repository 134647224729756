import { merge } from 'lodash';
import Base from '../crud-base-module.js';

import toHierarchy from './toHierarchy';
import { TASK_REL_PARENT } from '../task_relation/constants';

export default merge(
  {
    state: {
      parentTasks: [],
      favTasks: [],
      userTasks: [],
      subTasks: []
    },
    getters: {
      parentTasks: state => state.parentTasks,
      favTasks: state => state.favTasks,
      userTasks: state => state.userTasks,
      subTasks: state => state.subTasks
    },
    mutations: {
      userTasks(state, val) {

        state.userTasks = val
      },
      updateTask(state, task) {
        const index = state.parentTasks.findIndex(i => i.id === task.id)
        Vue.set(state.parentTasks, index, task);
      }
    },
    actions: {
      // TODO: Do we actually have to care about related tasks here?
      // TODO: This can take quite long, maybe move this logic to the server
      // TODO: The above comment is right... this should definitve be handled on the server
      deleteTaskRecursive: async function ({ dispatch }, { id }) {
        const relations = await dispatch('findTask_rels', {
          data: {
            or: {
              from_id: id,
              to_id: id,
            },
          },
        });

        for (const relation of relations) {
          if (relation.type === TASK_REL_PARENT && relation.from_id === id) {
            await dispatch('deleteTaskRecursive', { id: relation.to_id });
          } else {
            await dispatch('deleteTask_rel', { id: relation.id });
          }
        }

        await dispatch('deleteTask', { id });
      },
      loadTasks: async function ({ dispatch }, { filter } = {}) {
        return await dispatch('findTasks', {
          data: {
            select: ['id', 'title', 'milestone_id', 'project_id', 'user_id', 'due', 'state', 'has_attachment', 'interval'],
            and: filter,
            order: {
              project_id: 'ASC',
              due: 'ASC',
            },
            with: {
              milestone: {
                one: 'milestone',
                this: 'milestone_id',
                that: 'id',
                query: {
                  select: ['id','name', 'due']
                }
              },
              project: {
                one: 'project',
                this: 'project_id',
                that: 'id',
                query: {
                  select: ['id', 'name', 'is_private', 'state'],
                  with: {
                    'projectPersons': {
                      'many': 'project_person',
                      'this': 'id',
                      'that': 'project_id',
                      'query': {
                        select: ['id', 'user_id', 'project_id'],
                        'with': {
                          'user': {
                            'one': 'users',
                            'this': 'user_id',
                            'that': 'id',
                            'query': {
                              select: ['id'],
                            }
                          },
                          'addresses': {
                            'many': 'person_address',
                            'this': 'user_id',
                            'that': 'user_id'
                          }
                        }
                      }
                    }
                  }
                }
              },
              subtasks: {
                many: 'subtask',
                this: 'id',
                that: 'task_id'
              },
              // childTaskRefs: {
              //   many: 'task_rel',
              //   this: 'id',
              //   that: 'from_id',
              //   query: {
              //     select: ['id','to_id'],
              //     with: {
              //       task: {
              //         'one': 'task',
              //         'this': 'to_id',
              //         'that': 'id',
              //         select:['to_id'],
              //         query: {
              //           with: {
              //             user: {
              //               one: 'users',
              //               this: 'user_id',
              //               that: 'id',
              //               select: ['id','role_id']
              //             }
              //           }
              //         }
              //       }
              //     }
              //   }
              // },
              user: {
                one: 'users',
                this: 'user_id',
                that: 'id',
                query: {
                  select: ['id', 'role_id', 'name'],
                  with: {
                    role: {
                      one: 'role',
                      this: 'role_id',
                      that: 'id',
                    }
                  }
                }
              },
              /*
              createdBy: {
                one: 'users',
                this: 'created_by',
                that: 'id',
              },
              */
              comments: {
                many: 'comment',
                this: 'id',
                that: 'task_id',
                query: {
                  select: ['id', 'task_id'],
                  with: {
                    documents: {
                      many: 'document',
                      this: 'id',
                      that: 'item_id',
                      query: {
                        and: {
                          type: 'comment_attachment'
                        }
                      }
                    }
                  },
                }
              }
            },
          },
        });
      },
      loadFavTasks: async function ({ dispatch }, filter) {
        return await dispatch('findTask_favs', {
          data: {
            and: filter
          },
        });
      },
      loadTaskHierarchy: async function ({ dispatch }, { filter } = {}) {
        const tasks = await dispatch('loadTasks', { filter });
        const relations = await dispatch('findTask_rels', {
          and: {
            from_id: tasks.map(task => task.id),
            type: TASK_REL_PARENT,
          },
        });
        return toHierarchy(tasks, relations);
      },

    },
  },
  Base('task')
);
