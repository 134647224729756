import filterMaterials from './filter-materials'

export default (positions, categories) => {
  return positions.filter(p => {
    if (p.materials) {
      return filterMaterials(p.materials, categories)
    } else {
      return false
    }
  })
}
