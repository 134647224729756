export default (positions, categories = []) => {
  const items = []
  positions.forEach(pos => {
    if (pos.materials) {
      pos.materials.forEach(m => {
        const item = Object.assign({}, m)
        item.pos = pos;
        items.push(item)
      })
    }
  })

  if (categories.length > 0) {
    return items.filter(mp => {
      if (mp.category) {
        return categories.includes(mp.category.name)
      }
      return false
    })
  }
  
  return items
}
