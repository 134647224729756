<template>
  <flat-picker
    autocomplete="off"
    class="form-control datepicker"
    placeholder="Termin auswählen"
    :config="conf || dateConfig"
    :value="value"
    @input="onInput"
  ></flat-picker>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import FlatPicker from "vue-flatpickr-component";

export default {
  name: "apa-date-picker",
  components: {
    FlatPicker
  },
  props: {
    value: {
      type: String
    },
    conf: {
      type: Object
    }
  },
  data () {
    return {
      dateConfig: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "d.m.Y"
      }
    }
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
