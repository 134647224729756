<template>
  <el-select :disabled="disabled || isLoading" :multiple="multiple" :value="value" @input="onInput" filterable>
    <el-option
      v-if="nullable"
      :label="typeof nullable === 'string' ? nullable : '(No user)'"
      :value="null"
    />
    <el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id"></el-option>
  </el-select>
</template>

<script>
import { isEqual } from "lodash";

export default {
  name: "apa-user-select",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object
    },
    multiple: {
      type: Boolean
    },
    nullable: {
      type: [Boolean, String]
    },
    value: {
      type: [Array, Number]
    }
  },
  data() {
    return {
      isLoading: true,
      users: []
    };
  },
  watch: {
    filter(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function() {
      this.isLoading = true;
      const users = await this.$store.dispatch("findUsers", {
        data: {
          and: this.filter
        },
        options: {
          commit: false
        }
      });

      this.isLoading = false;
      this.users = users.filter((u) => u.role_id !== 18).sort((left, right) =>
        left.name.localeCompare(right.name)
      )
    },
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
