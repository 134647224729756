// mport api from 'api-client'
import newsItems from './news.json'

const state = {
    news: []
}

const getters = {
    news: state => state.news
}

const mutations = {
    news (state, news) {
        state.news = news
    },
    addNews (state, news) {
        state.news.push(news)
    }
}

const actions = {
    loadNews ({ commit }) {
        commit('news', newsItems)
    },
    addNews ({ commit }, news) {
        commit('addNews', news)
    }
}

export default {
    state, getters, mutations, actions
}
