<template>
  <div class="text-right">
    <i class="fas fa-paperclip"></i> &nbsp;
    <a v-for="doc in documents" :href="doc.url" target="_blank">{{display(doc)}}</a>
  </div>

</template>

<script>
import Vue from 'vue'
import CommentForm from "./CommentForm";

export default {
  name: "comment",
  components: {
    CommentForm
  },
  props: {
    documents: {
      type: Array
    }
  },
  data() {
    return {
      isAnswerVisible: false
    };
  },
  methods: {
    async createCommentCallback(comment) {
      await this.createComment({
        pred_id: this.comment.id,
        ...comment
      });

      this.isAnswerVisible = false;
    },
    display(doc) {
      return decodeURIComponent(doc.doc.substring(doc.doc.lastIndexOf('/')+1))
    },
    getDownloadPath(document) {
        const base = Vue.http.options.root + '../../storage/comment_attachment/';
        const { item_id, name, path } = document;
        return `${base}${item_id}/${path}/${name}`
    }
  }
};
</script>
