const sortByDate = (a, b) =>  (b.created > a.created) ? 1 : (b.created < a.created) ? -1 : 0

export default function toHierarchy(comments) {
  comments = comments.map(comment => ({
    ...comment,
    children: [],
  }));

  for (const comment of comments) {
    const { pred_id } = comment;
    const parent = comments.find(parent => parent.id === pred_id);

    if (parent) {
      parent.children.push(comment);
      parent.children = parent.children.sort(sortByDate)

    }
  }
  return comments.filter(comment => comment.pred_id === null)
    .sort(sortByDate);
}
