import { PRODUKTIONSWARE, VERBRAUCHSMATERIAL, EINKAUFSWARE, EXTERNES_LAGER, ANGEBOTSARTIKEL, LAGER_UND_PRODUKTION, LAGERWARE, BESTANDSWARE, INHOUSE } from '../material/materialCategories'

export default [
  PRODUKTIONSWARE,
  VERBRAUCHSMATERIAL,
  BESTANDSWARE,
  EINKAUFSWARE,
  EXTERNES_LAGER,
  ANGEBOTSARTIKEL,
  //INHOUSE,
  LAGERWARE
]