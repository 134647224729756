import Vue from 'vue'
import Base from '../crud-base-module.js'
import { merge } from 'lodash'

export default merge({
    actions: {
        getProjectPersons ({ commit, dispatch }, projectId) {
            return Vue.http.post(`project_person/query`, {
              'and': {
                  'project_id': projectId
              },
              'with': {
                'user': {
                  'one': 'users',
                  'this': 'user_id',
                  'that': 'id'
                },
                'addresses': {
                    'many': 'person_address',
                    'this': 'user_id',
                    'that': 'user_id',
                    'query': {
                      'and': {
                        'project_id': projectId
                      },
                      'with': {
                        'address': {
                          'one': 'address',
                          'this': 'address_id'
                        }
                      }
                    }
                }

              }
            })
            .then(res => res.json())
            // .then(res => {
            //     return res.map(i => {
            //         i.addresses = i.addresses ? i.addresses.map(a => a.address_id) : []
            //         return i
            //     })
            // })
            // .then(addressRefs => {
            //   const addresses = addressRefs.map(i => {
            //     i.address.milestones = i.milestones
            //     return i.address
            //   })
            //   commit('SET_PROJECT_ADDRESSES', addresses)
            //   return addresses
            // })
          },
          loadUsers ({ commit, dispatch }) {
            return dispatch('findUsers', {
                data: {
                    'with': {
                        'avatar': {
                            'one': 'document',
                            'this': 'id',
                            'that': 'item_id',
                            'query': {
                                'and': {
                                    'type': 'users',
                                    'path': 'avatar'
                                }
                            }
                        },
                        'role': {
                            'one': 'role',
                            'this': 'role_id',
                            'that': 'id'
                        }
                    }
                },
                options: {
                    commit: true
                }
            })

          },
          getUserWithRights ({ commit, dispatch }, userId) {
            return dispatch('findUsers', {
                data: {
                    'and': {
                        'id': userId
                    },
                    'with': {
                        'rights': {
                            'many': 'right',
                            'this': 'role_id',
                            'that': 'role_id'
                        },
                        'role': {
                            'one': 'role',
                            'this': 'role_id',
                            'that': 'id'
                        }
                    }
                },
                options: {
                    commit: false
                }
            })
            .then(results => results.length > 0 ? results[0] : null)
          },
          loadPersonsForProject ({ dispatch }, projectId) {
            return dispatch('findProject_people', {
                data: {
                    'and': {
                        'project_id': projectId
                    },
                    'with': {
                        'user': {
                            'one': 'users',
                            'this': 'user_id',
                            'that': 'id'
                        },
                        'addresses': {
                            'many': 'person_address',
                            'this': 'user_id',
                            'that': 'user_id'
                        }
                    }
                }
            })
        }
    }
}, Base('user', 'users'))
