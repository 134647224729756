import Vue from 'vue'
import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({
    actions: {
        loadPersonAddresses({ dispatch }, projectId ) {
            return dispatch('findPerson_addresses', {
                data: {
                    'and': {
                        'project_id': projectId
                    },
                    'with': {
                        'user': {
                            'one': 'users',
                            'this': 'user_id',
                        },
                        'address': {
                            'one': 'address',
                            'this': 'address_id'
                        },
                        'hotel': {
                            'one': "address",
                            'this': "hotel_id"
                        }
                    }
                },
                options: {
                    commit: true
                }
            })
        },
        deleteProjectPersonAddresses({ dispatch }, { projectId, userId }) {
            return Vue.http.post(`person_address/query`, {
                'and': {
                    'project_id': projectId,
                    'user_id': userId
                }
              })
              .then(res => res.json())
              .then(res => {
                  return res.map(i => {
                    dispatch('deletePerson_address', { id: i.id })
                  })
            })
        }
    }
}, Base('person_address'))

// {
//   description:	
//   Einsatzorte eines Projektes.
  
//   id	integer
//   Unique id of the item.
  
//   client_id	integer
//   Client the item lives in.
  
//   project_id	integer
//   Das Projekt.
  
//   address_id	integer
//   Der Ort.
  
//   }