<template>
  <div class="btn-group btn-group-toggle mr-2" data-toggle="buttons">
    <label class="btn btn-secondary" :class="{ active: isCompact }">
      <input type="radio" id="on" :value="true" v-model="isCompact" > <i class="fas fa-compress-arrows-alt"></i>
    </label>
    <label class="btn btn-secondary" :class="{ active: !isCompact }">
      <input type="radio" id="off" :value="false" v-model="isCompact"> <i class="fas fa-th-list"></i>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCompact: true
    }
  },
  watch: {
    isCompact (newVal) {
      this.$emit('input', newVal)
    }
  },
  mounted () {
    this.isCompact = this.value
  }
}
</script>