import { merge } from 'lodash';

import Base from '../crud-base-module.js';
import toHierarchy from './toHierarchy';

export default merge(
  {
    getters: {
      commentsWithChildren(state) {
        return toHierarchy(state.comments);
      },
    },
    actions: {
      loadComments({ dispatch }, { filter, order, page }) {
        const query = {
          and: filter,
          with: {
            user: {
              one: 'users',
              this: 'user_id',
            },
            documents: {
              many: 'document',
              this: 'id',
              that: 'item_id',
              query: {
                and: {
                  type: 'comment_attachment'
                }
              }
            }
          },
        }
        if (order) query.order = order
        if (page) query.page = page
        return dispatch('findComments', {
          data: query,
          options: {
            commit: false,
          },
        });
      },
    },
  },
  Base('comment')
);
