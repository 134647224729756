<template>
  <div class="btn-group">
    <button type="button" class="btn"
      :class="[
      { 'btn-icon btn-fab': icon },
      { [`btn-${type}`]: type },
      { [`btn-${size}`]: size },
      { disabled: disabled && tag !== 'button' }
      ]"
      @click="onPrimaryClick">
      <slot name="content">
        {{ text }}
      </slot>
    </button>
    <button type="button" class="btn dropdown-toggle dropdown-toggle-split" aria-haspopup="true" aria-expanded="false" :class="'btn-' + type" @click="toggleDropdown">
      <span class="sr-only">Toggle Dropdown</span>
    </button>
    <div ref="dropdown" class="dropdown-menu" :class="[{ 'dropdown-menu-right': menuOnRight }]" v-click-outside="closeDropdown">
      <slot/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-split-button',
  props: {
    tag: {
      type: String,
      default: 'button',
      description: 'Button html tag'
    },
    text: {
      type: String,
      required: false
    },
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    wide: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: 'default',
      description: 'Button type (primary|secondary|danger etc)'
    },
    nativeType: {
      type: String,
      default: 'button',
      description: 'Button native type (e.g button, input etc)'
    },
    size: {
      type: String,
      default: '',
      description: 'Button size (sm|lg)'
    },
    outline: {
      type: Boolean,
      description: 'Whether button is outlined (only border has color)'
    },
    link: {
      type: Boolean,
      description: 'Whether button is a link (no borders or background)'
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether menu should appear on the right'
    },
  },
  data () {
    return {
      // I have no idea... Aber wenn das hier true ist, öffnet sich das Menü
      isVisible: false
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
    closeDropdown (e) {
      if (e.target.className === 'btn dropdown-toggle dropdown-toggle-split btn-primary') {
        return
      }
      if (this.isVisible) {
        this.isVisible = false
        this.$refs.dropdown.classList.remove('show')
      }
    },
    toggleDropdown () {
      this.isVisible = !this.isVisible;
      this.$refs.dropdown.classList.toggle('show')
    },
    onPrimaryClick () {
      // this.toggleDropdown()
      this.$emit('primary-click')
    }
  }
};
</script>
<style scoped lang="scss">
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /deep/ i {
    padding: 0 3px;
  }
}

.dropdown-menu {
  transform: translate(-30px, 0px) !important;
}
</style>
