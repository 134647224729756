<template>
   <img alt="Image placeholder" class="" :src="icon">
</template>
<script>
import mime from 'mime-types'
import { min } from 'd3';

  export default {
    name: 'FileIcon',
    props: {
      mimeType: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        icon: null
      }
    },
    mounted () {
      try {
        const ext = mime.extension(this.mimeType)
        this.icon = require('./Icons/' + ext + '.png')
      } catch(error) {
        this.icon = require('./Icons/_blank.png')
      }
    }
  }
</script>
<style>
</style>
