
export default [
    {
      img: 'img/theme/bootstrap.jpg',
      name: 'DFB-Pokalfinale 2019',
      customer: 'DFB',
      eventDate: '01.12.2019',
      projectId: 'P0001',
      status: 'Ladeliste',
      statusType: 'warning',
      completion: 60,
      isFavorite: true,
      ladetermin: '01.09.2019',
      deadlineDruck: '01.08.2019',
      progress: [
        {
          type: 'danger',
          progress: 0,
          name: 'BHB'
        },{
          type: 'warning',
          progress: 0,
          name: 'Kommissionslinste'
        },{
          type: 'info',
          progress: 0,
          name: 'Ladeliste'
        },{
          type: 'success',
          progress: 100,
          name: 'Rücklagerung'
        }
      ]
    },
    {
      img: 'img/theme/angular.jpg',
      name: 'BMW IBU World Cup Biathlon',
      customer: 'DFB',
      eventDate: '10.11.2019',
      projectId: 'P0002',
      status: 'BHB',
      statusType: 'success',
      completion: 100,
      isFavorite: true,
      ladetermin: '01.09.2019',
      progress: [
        {
          type: 'danger',
          progress: 50,
          name: 'BHB'
        },{
          type: 'warning',
          progress: 10,
          name: 'Kommissionslinste'
        },{
          type: 'info',
          progress: 20,
          name: 'Ladeliste'
        },{
          type: 'success',
          progress: 20,
          name: 'Rücklagerung'
        }
      ]

    },
    {
      img: 'img/theme/sketch.jpg',
      name: ' FIS World Snow Day',
      customer: 'DFB',
      eventDate: '01.12.2019 - 24.12.2019',
      projectId: 'P0003',

      status: 'Ladeliste',
      statusType: 'warning',
      completion: 72,
      isFavorite: true,
      ladetermin: '01.09.2019',
      progress: [
        {
          type: 'danger',
          progress: 0,
          name: 'BHB'
        },{
          type: 'warning',
          progress: 10,
          name: 'Kommissionslinste'
        },{
          type: 'info',
          progress: 10,
          name: 'Ladeliste'
        },{
          type: 'success',
          progress: 80,
          name: 'Rücklagerung'
        }
      ]

    },
    {
      img: 'img/theme/react.jpg',
      name: 'Mercedes Benz Fashionweek',
      customer: 'DFB',
      eventDate: '01.12.2019 - 24.12.2019',
      projectId: 'P0004',
      status: 'Ladeliste',
      statusType: 'warning',
      completion: 90,
      isFavorite: false,
      ladetermin: '01.09.2019',
      progress: [
        {
          type: 'danger',
          progress: 10,
          name: 'BHB'
        },{
          type: 'warning',
          progress: 30,
          name: 'Kommissionslinste'
        },{
          type: 'info',
          progress: 10,
          name: 'Ladeliste'
        },{
          type: 'success',
          progress: 50,
          name: 'Rücklagerung'
        }
      ]
    },
    {
      img: 'img/theme/vue.jpg',
      name: 'BOMAG GmbH',
      customer: 'DFB',
      eventDate: '01.12.2020',
      projectId: 'P0005',
      status: 'BHB',
      statusType: 'success',
      completion: 100,
      isFavorite: false,
      ladetermin: '01.09.2019',
      progress: [
        {
          type: 'danger',
          progress: 50,
          name: 'BHB'
        },{
          type: 'warning',
          progress: 10,
          name: 'Kommissionslinste'
        },{
          type: 'info',
          progress: 20,
          name: 'Ladeliste'
        },{
          type: 'success',
          progress: 20,
          name: 'Rücklagerung'
        }
      ]

    },
    {
      img: 'img/theme/bootstrap.jpg',
      name: 'DFB-Pokalfinale 2018',
      customer: 'DFB',
      eventDate: '01.12.2021',
      projectId: 'P0006',

      status: 'Ladeliste',
      statusType: 'warning',
      completion: 60,
      isFavorite: false,
      ladetermin: '01.09.2019',
      progress: [
        {
          type: 'danger',
          progress: 25,
          name: 'BHB'
        },{
          type: 'warning',
          progress: 25,
          name: 'Kommissionslinste'
        },{
          type: 'info',
          progress: 10,
          name: 'Ladeliste'
        },{
          type: 'success',
          progress: 40,
          name: 'Rücklagerung'
        }
      ]

    },
  ]
  