import Vue from 'vue'
import { getItem, setItem, removeItem } from '@/store'
import docs from '../docs'

const state = {
    authToken: getItem('authToken', null),
    currentUser: getItem('currentUser', null),
    // roles: []
};

const getters = {
    authToken: state => state.authToken,
    currentUser: (state, rootState) => {
        return state.currentUser
    },
    currentUserRights: state => state.currentUser && state.currentUser.rights ? state.currentUser.rights.filter(r => r.tables === 'function').map(r => r.actions): []
    // roles: state => state.roles
};

const mutations = {
    authToken (state, authToken) {
        setItem('authToken', authToken);
        state.authToken = authToken
    },
    currentUser (state, user) {
        setItem('currentUser', user);
        state.currentUser = user
    },
    clearAuth (state) {
        state.currentUser = null;
        state.authToken = null;
        removeItem('currentUser');
        removeItem('authToken')
    }
};

const actions = {
    setAuthToken ({ commit }, authToken) {
        commit('authToken', authToken)
    },
    setCurrentUser ({ dispatch, commit }, user) {
        commit('currentLocale', user.lang);
        return dispatch('getDocs', { type: 'users', id: user.id, api:true })
            .then(docs => {
                user.avatar = docs.find(doc => doc.path='avatar');
                return commit('currentUser', user)
            })
    },
    refreshCurrentUser ({ dispatch, commit, state }) {
        const userId = state.currentUser.id;
        return dispatch('getUserWithRights', userId)
            .then(user => dispatch('setCurrentUser', user))
    },
    clearAuth ({ commit }) {
        commit('clearAuth')
    },
    toggleFavProject({ dispatch, commit, state }, id) {
        let ids = state.currentUser.fav_projects ? state.currentUser.fav_projects.split(',') : [];
        ids = ids.map(id => parseInt(id));
        const index = ids.indexOf(id);
        if (index==-1) {
            ids.push(id)
        } else {
            ids.splice(index, 1)
        }
        const user = Object.assign({}, state.currentUser);
        user.fav_projects = ids.join(',');
        dispatch('updateUser', {id:state.currentUser.id, data: {fav_projects: user.fav_projects}})
          .then(() => commit('currentUser', user))
    }
};

export default {
    state, getters, mutations, actions
}
