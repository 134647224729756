
import Vue from 'vue'
import pluralize from 'pluralize'
import { merge } from 'lodash'

const defaultActions = [
    {
        actionName: 'get',
        httpMethod: 'get',
        isPlural: false,
        commit: true
    },
    {
        actionName: 'update',
        httpMethod: 'put',
        isPlural: false,
        commit: false
    },
    {
        actionName: 'delete',
        httpMethod: 'delete',
        isPlural: false,
        commit: false
    },
    {
        actionName: 'create',
        httpMethod: 'post',
        isPlural: false,
        commit: false
    },
    {
        actionName: 'find',
        httpMethod: 'post',
        additional: '/query',
        isPlural: true,
        commit: true
    }
];

const uppercaseFirst = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
};

export default (name, urlSlug = null, defActions = defaultActions) => {
    const upperName = uppercaseFirst(name);
    const pluralName = pluralize(name);
    const pluralUpperName = uppercaseFirst(pluralName);

    const state = {
        [name]: null,
        [pluralName]: []
    };

    const getters = {
        [name]: state => state[name],
        [pluralName]: state => state[pluralName]
    };

    const mutations = {
        [name]: (state, value) => state[name] = value,
        [pluralName]: (state, value) => state[pluralName] = value
    };

    const actions = {};

    defActions.forEach(a => {
        const newActionName = `${a.actionName}${a.isPlural ? pluralUpperName : upperName}`;
        actions[newActionName] = ({ commit }, params) => {
          params = params || {};
          let data = null;
          let url = `${urlSlug ? urlSlug : name}`;
   
          let options = params.options || {}
          options = Object.assign({}, a, options)

          if (params) {
              if (params.data) {
                  data = clearData(params.id, params.data)
              }

              if (params.id) {
                if (Array.isArray(params.id)) {
                  url = `${url}/${params.id.join(',')}`
                } else {
                  url = `${url}/${params.id}`   
                }
              }
          }

          if (a.additional) {
              url = url + options.additional
          }

          let result = Vue.http[options.httpMethod](url, data)
              .then(res => res.json());

          if (options.commit) {
              const mutationName = options.mutationName
                ? options.mutationName
                : options.isPlural ? pluralName : name;


              result = result.then(res => {
                  res = options.mutationContext ? Object.assign(options.mutationContext, res) : res
                  commit(mutationName, res);
                  return res
              })
          }
          return result
        };

        function clearData(id, data) {
          if (a.actionName == 'update') {
            if (!id) { // bulk
              for(var key in data) {
                data[key] = removeComplexAttributes(data[key])
              }
              return data;
            } else {
              return removeComplexAttributes(data)
            }
          } else if (a.actionName=='create') {
            if (Array.isArray(data)) { // bulk
              return data.map(item => removeComplexAttributes(item))
            } else {
              return removeComplexAttributes(data)
            }
          } else {
            return data;
          }

          function removeComplexAttributes(data) {
            if (isComplex(data)) {
              const result = {};
              for(var key in data) {
                if (data[key] === null || typeof data[key]!='object') {
                  result[key] = data[key];
                }
              }
              return result;
            } else {
              return data;
            }
          }

          function isComplex(data) {
            for(var key in data) {
              if (data[key] !== null && typeof data[key]=='object') {
                return true
              }
            }
            return false
          }
      }
    });


    return { state, getters, mutations, actions}
}