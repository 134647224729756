import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import api from '@/util/api.js'

export default merge({
  actions: {
    loadProjectOrders({ commit, dispatch }, projectId) {
      api('findProject_orders')
        .and('project_id', projectId)
        .with('order', {'one': 'order', 'this': 'order_no', 'that': 'No'})
        .run()
        .then(items => {
          commit('project_orders', items);
          return items
        })
    }
  }
}, Base('project_order'))

// {
//   description:	
//   Einsatzorte eines Projektes.
  
//   id	integer
//   Unique id of the item.
  
//   client_id	integer
//   Client the item lives in.
  
//   project_id	integer
//   Das Projekt.
  
//   address_id	integer
//   Der Ort.
  
//   }