<template>
  <modal @update:show="onShowChange" :show="show" size="xl">
    <h6 slot="header" class="modal-title">
      {{ task.id ? this.$t("message.taskEdit") : this.$t("message.taskNew") }}
    </h6>
    <div v-loading="isLoading">
      <base-input label="Übergeordnete Aufgabe" v-if="task.parent" :disabled="true" :value="task.parent.title" />

      <div class="row">
        <div class="col-md-4">
          <base-input :label="$t('task.user_id')">
            <el-select v-model="task.user_id" filterable>
              <el-option v-for="option in allowedUsers" :key="option.id" :label="option.name" :value="option.id">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input :label="$t('task.due')">
            <apa-date-picker v-model="task.due" />
          </base-input>
        </div>
        <div class="col-md-3" v-if="!task.parent">
          <base-input :label="$t('task.project_id')">
            <apa-project-select nullable v-model="task.project_id" :valid-projects="validProjects" />
          </base-input>
        </div>
        <div class="col-md-3" v-if="task.project_id">
          <base-input :label="$t('task.milestone_id')">
            <apa-milestone-select nullable v-model="task.milestone_id" :filter="{ project_id: task.project_id }" />
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input :label="$t('task.title')" :placeholder="$t('task.new.title')" v-model="task.title"
            :max-length="200" :validator="$v.task.title"></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input :label="$t('task.description')" :placeholder="$t('task.description')">
            <quill-editor v-model="task.description" ref="myQuillEditor" :options="editorOption"></quill-editor>
          </base-input>
        </div>
      </div>

      <div class="row">

        <div class="col-md-3">
          <base-input :label="$t('task.recurring_task')">
            <el-select v-model="task.interval" @change="resetRepeat" class="select-primary">
              <el-option :label="$t('message.no')" :value="null" />
              <el-option v-for="step in intervalSteps" :value="step" :label="$t('task.' + step)"
                :key="step"></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-md-3" v-if="task.interval !== null && task.interval !== 'daily'">
          <div class="d-flex" v-if="task.interval === 'weekly'">
            <base-input :label="$t('task.every')">
              <el-select v-model="task.interval_repeat" class="select-primary">
                <el-option v-for="day in stepWeekdays" :value="day" :label="$t('task.' + day)" :key="day"></el-option>
              </el-select>
            </base-input>
          </div>
          <div v-if="task.interval === 'monthly'">
            <base-input :label="$t('task.every')">
              <el-select v-model="task.interval_repeat" class="select-primary">
                <el-option v-for="day in 28" :value="day" :label="day" :key="day"></el-option>
              </el-select>
            </base-input>
            {{ $t('task.of_month') }}
          </div>
          <div class="d-flex" v-if="task.interval === 'yearly'">
            <base-input :label="$t('task.always_at')">
              <apa-date-picker v-model="task.interval_repeat" :conf="intervalDateConfig" />
            </base-input>
          </div>
        </div>
        <div class="col-md-3" v-if="task.interval !== null">
          <base-input :label="$t('task.start_date')">
            <apa-date-picker v-model="task.start_interval" />
          </base-input>

          <base-input :label="$t('task.end_date')">
            <apa-date-picker v-model="task.end_interval" />
          </base-input>
        </div>

      </div>

      <base-checkbox class="col-4 checkbox" :checked="isDone"
        :disabled="task.children && task.children.length > 0 && hasOpenChildTasks" @cclick="onDoneClick"
        v-if="!isChild && task.interval === null">
        {{ $t('task.mark_as_done') }}
      </base-checkbox>

      <task-checklist :task-id="task.id" :items="task.subtasks || []" @create="onChecklisCreated"
        @update="onChecklistUpdate" @delete="onChecklistItemDelete" />
      <div class="row" v-if="task.childTaskRefs && task.childTaskRefs.length > 0">
        <div class="col-md-12">
          <base-input :label="$t('task.subtasks')">
            <ul class="list-group list-group-flush list-group-sub" data-toggle="checklist">
              <task-item v-for="{ task: sub } in task.childTaskRefs" :is-child="true" :key="sub.id" :show-tools="false"
                :task="sub" @showSub="showSub(sub)" :show-done-tasks="true"></task-item>
            </ul>
          </base-input>
        </div>
      </div>

      <apa-comments
        @emitComment="createNewFromComment"
        @attachmentUploaded="commentAttachmentUploaded($event)"
        @created="setCommentId($event)"
        enableDrag
        :label="$t('message.comments')"
        :createAlsoAsDebriefing="true"
        :hideComments="false"
        :fixtures="{
          project_id: task.project_id,
          task_id: task.id ? task.id : '<> NULL',
          type: 'TASK_COMMENT',
        }" />

      <div class="row" v-loading="isHistoryLoading">
        <div class="col-md-12">
          <h4 @click="toggleHistory()" class="clickable">
            {{
              $t("task.history.title")
            }}<i class="ml-2" :class="{
                                      'fa fa-chevron-down': !isHistoryVisible,
                                      'fa fa-chevron-up': isHistoryVisible,
                                    }"></i>
          </h4>
          <div v-if="logs.length > 0 && isHistoryVisible" class="history text-sm">
            <div class="d-flex justify-content-between border-bottom mb-1" v-for="item in logs" :key="item.id"
              v-if="!['task_fav'].includes(item.type)">
              <span class="history-title">{{
              `${$t(`log.type.${item.type}`)} ${$t(
                `log.operation.${item.operation}`
              )}`
              }}</span>
              <div>
                <div class="text-muted">{{ item.created_at | datetime }}</div>
                <div class="name">
                  {{ $t("log.user", { name: item.user.name }) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template slot="footer">
      <span v-if="task.createdBy">{{ $t("message.from") }} {{ task.createdBy.name }}</span>
      <base-button type="link" class="ml-auto" @click="onClose">{{
        $t("message.close")
      }}</base-button>
      <base-button type="primary" @click="onSave">{{
        $t("message.save")
      }}</base-button>
    </template>
  </modal>
</template>
<script>
import { TASK_REL_PARENT } from "@/store/modules/task_relation/constants";
import { TASK_CLOSED, TASK_NEW, TASK_EXTERN_ROLES } from "@/store/modules/tasks/constants";

import { mapGetters } from 'vuex'
import ApaComments from "@/components/ApaComments/ApaComments";
import ApaDatePicker from "@/components/ApaInputs/ApaDatePicker";
import ApaMilestoneSelect from "@/components/ApaInputs/ApaMilestoneSelect";
import ApaProjectSelect from "@/components/ApaInputs/ApaProjectSelect";
import ApaUserSelect from "@/components/ApaInputs/ApaUserSelect";
import pickTask from "@/store/modules/tasks/pickTask";
import TaskChecklist from "./Checklist";
import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import TaskItem from "./TaskItem";
import createTask from "@/store/modules/tasks/createTask";

export default {
  name: "task-form",
  components: {
    ApaComments,
    ApaDatePicker,
    ApaMilestoneSelect,
    ApaProjectSelect,
    ApaUserSelect,
    TaskChecklist,
    HtmlEditor,
    TaskItem,
  },
  props: {
    fixedProjectId: {
      type: Number,
    },
    show: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
    },
    validProjects: {
      type: Array,
      default: null
    }
  },
  watch: {
    'task.project_id'() {
      this.task.milestone_id = null;
    }
  },
  data() {
    return {
      isLoading: false,
      isHistoryLoading: false,
      logs: [],
      isHistoryVisible: false,
      commentId: null,
      quillValue: null,
      editorOption: {
        modules: {
          toolbar: {
            container: [["emoji"]],
            handlers: { emoji: function () { } },
          },
          keyboard: {
            bindings: {
              custom: {
                key: 186,
                shiftKey: true,
                handler: function (range, context) {
                  this.quill.insertText(range.index, 'Ü');
                }
              }
            },
          },
          "emoji-shortname": true,
          "emoji-toolbar": true,
          "emoji-textarea": true,
        },
      },
      intervalSteps: ['daily', 'weekly', 'monthly', 'yearly'],
      stepWeekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      intervalDateConfig: {
        dateFormat: "m-d",
        altInput: true,
        altFormat: "d.m."
      }
    };
  },
  computed: {
    ...mapGetters([
        'roles',
        'users',
        'projectAddresses'
    ]),
    addressFilterValues() {
      const temp = [...new Set(this.projectAddresses)];
      temp.unshift({address: { id: 0, name: "Alle"}});
      return temp;
    },
    isDone: {
      get() {
        return this.task.state === TASK_CLOSED;
      },
      set(value) {
        this.task.state = value ? TASK_CLOSED : TASK_NEW;
      },
    },
    hasOpenChildTasks() {
      return !!this.task.children.filter(t => t.state === TASK_NEW).length
    },
    allowedUsers() {
      const internalRoles = this.roles.filter(r => !TASK_EXTERN_ROLES.includes(r.name));
      let internalRoleIds = [];
      internalRoles.forEach(ir => internalRoleIds.push(ir.id));
      const internalUsers = this.users.filter(u => internalRoleIds.includes(u.role_id));
      return internalUsers;
    }
  },
  validations: {
    task: {
      title: { required },
    },
  },
  methods: {
    resetRepeat() {
      this.task.interval_repeat = null;
    },
    async commentAttachmentUploaded(params) {
      this.task.has_attachment = 1;
      if (this.task.id) {
        await this.$store.dispatch("updateTask", {
          id: this.task.id,
          data: { has_attachment: 1 }
        });
      }
    },
    createNewFromComment(text, thisComment, predID) {

      //      console.log(predID);
      //      console.log(this.task);
      //      console.log(this.task.comments);

      //      while(predID !== null){
      //        let newPredID = this.task.comments.find(item => item.id === this.pred_id);
      //        predID = newPredID;
      //        console.log("went one step up");
      //      };


      const user = this.task.user;
      const taskProject = this.task.project;
      this.task = createTask({
        title: text,
        project_id: taskProject,
        user_id: user.id,
      });
      this.showForm = true;
    },
    showSub(task) {
      this.$emit("command", 'navigate', task, {});
    },
    setCommentId(c_id) {
      if (!this.task.id) {
        this.commentId = c_id;
      }
    },
    onDoneClick(e) {
      const { task } = this;
      const newValue = e.target.checked ? TASK_CLOSED : TASK_NEW;

      e.preventDefault();

      this.$confirm(
        `Wollen Sie den Status dieser Aufgabe ändern?`,
        `Aufgabe`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning"
        }
      ).then(() => {
        task.state = newValue
        this.onCheckboxClick(task, { state: task.state });
      })
    },
    async onCheckboxClick(task, newValue) {
      return this.$store.dispatch("updateTask", {
        id: task.id,
        data: {
          ...pickTask(task),
          newValue
        }
      });
    },
    onClose() {
      this.onShowChange(false);
    },
    onChecklistUpdate(item) {
      //this.loadLogs();
      this.$emit("refresh", this.task.id);
    },
    onChecklisCreated(item) {
      if (!Array.isArray(this.task.subtasks)) {
        this.task.subtasks = [];
      }
      this.task.title = this.task.title + '.'
      this.task.title = this.task.title.substring(0, this.task.title.length - 1);
      this.task.subtasks = [].concat(this.task.subtasks, [item])
      //this.loadLogs();
      this.$emit("refresh", this.task.id);
    },
    onChecklistItemDelete({ id }) {
      if (this.task.id === null) {
        this.task.title = this.task.title + '.'
        this.task.title = this.task.title.substring(0, this.task.title.length - 1);
        this.task.subtasks = this.task.subtasks.filter((item, index) => index !== id);
        this.$emit("refresh", this.task.id);
        return;
      }
      const item = this.task.subtasks.find((st) => st.id === id);
      this.task.subtasks.splice(this.task.subtasks.indexOf(item), 1);
      this.$emit("refresh", this.task.id);
    },
    onSave: async function () {
      const { $store, task } = this;
      let data = pickTask(task);
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("Validation failed", this.$v);
        return;
      }

      this.isLoading = true;

      try {
        if (data.id) {
          // Reset interval settings
          if (data.interval === null) {
            data.interval_repeat = null,
              data.start_interval = null,
              data.end_interval = null
          } else {
            data.due = null
          }
          await this.$store.dispatch("updateTask", {
            id: data.id,
            data,
          });
        } else {
          const newProjectId = !task.project_id ? this.fixedProjectId : task.project_id;

          if (task.interval) {
            task.due = null
          }

            const data = {
              title: task.title,
              project_id: newProjectId,
              user_id: task.user_id,
              description: task.description,
              due: task.due,
              milestone_id: task.milestone_id,
              created_by: task.created_by,
              state: 'NEW',
              interval: task.interval,
              interval_repeat: task.interval_repeat,
              start_interval: task.start_interval,
              end_interval: task.end_interval
            };
            const { id } = await this.$store.dispatch("createTask", { data });

            if (task.subtasks && task.subtasks.length > 0) {
              task.subtasks = task.subtasks.map((t) => {
                t.task_id = id
                return t
              });
              for (let index = 0; index < task.subtasks.length; index++) {
                const subTask = task.subtasks[index];
                await this.$store.dispatch('createSubtask', { data:subTask });
              }
            }

            if (this.commentId) {
              await this.$store.dispatch("updateComment", {
                id: this.commentId,
                data: { task_id: id }
              });
            }

            if (task.parent) {
              const data = {
                from_id: task.parent.id,
                to_id: id,
                type: TASK_REL_PARENT,
              };

              await this.$store.dispatch("createTask_rel", { data });
            }
          
        }

        this.isLoading = false;
        this.onShowChange(false);
        this.$emit("changed");
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: "Fehler beim Speichern: " + error,
          type: "error",
        });
      }
    },
    onShowChange(state) {
      this.$emit("update:show", state);
    },
    loadLogs() {
      this.isHistoryLoading = true;
      Vue.http
        .post(`log/query`, {
          or: {
            "content->task_id": this.task.id,
            and: {
              type: "task",
              item_id: this.task.id,
            },
          },
          with: {
            user: { one: "users", this: "user_id" },
          },
          order: {
            created_at: "desc",
          },
        })
        .then((res) => res.json())
        .then((log) => {
          this.logs = log;
          this.isHistoryLoading = false;
        });
    },
    toggleHistory() {
      this.loadLogs();
      this.isHistoryVisible = !this.isHistoryVisible;
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss">
.el-loading-mask {
  z-index: 1050;
}

.modal {
  overflow: auto;
}

.modal-footer {
  @media screen and (max-width: 667px) {
    padding-bottom: 3.5rem;
  }
}

.history {
  &.text-sm {
    font-size: 10px !important;
  }

  .name {
    font-weight: bold;
  }
}
</style>