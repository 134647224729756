import Vue from "vue"

export default async function (search, from = 0, size = 10) {
  const response = await Vue.http.get("search/" + search, { params: { from, size } });

  return response.body.map(hit => dataHit(hit))

  function dataHit(hit) {
    const result = {
      name: '/',
      type: hit.type,
      route: '/',
      data: hit.source,
      ctx: {}
    };
    switch (hit.type) {
      case 'project':
        result.name = hit.source.key + ' ' + hit.source.name;
        result.route = '/project/' + hit.id;
        break;
      case 'position':
        result.name = hit.source.project.key + ' ' + hit.source.project.name + ':' + hit.source.key;
        result.route = '/project/' + hit.source.project_id + '/data/' + hit.id;
        break;
      case 'comment':
        result.name = hit.source.project.key + ' ' + hit.source.project.name + ':' + hit.source.key;
        if (hit.source.position_id) {
          if(hit.source.type === 'DEBRIEFING'){
            result.route = '/project/' + hit.source.project_id + '/debriefing';
          }
          else{
            result.route = '/project/' + hit.source.project_id + '/bhb/' + hit.source.position_id + '?comment_id=' + hit.id;
          }
          result.ctx = { comment_id: hit.id }
        }
        // if (hit.source.task_id) {
        //   result.route = '/project/' + hit.source.project_id + '/tasks';
        //   result.ctx = { task_id: hit.source.task_id, comment_id: hit.id }
        // }
        break;
      case 'task':
        result.name = (hit.source.project ? hit.source.project.key : '') + ' ' + hit.source.title;
        result.route = '/project/' + hit.source.project_id + '/tasks';
        result.ctx = { task_id: hit.id };
        break;
      case 'option':
        result.type = 'area';
        result.name = (hit.source.project ? hit.source.project.key : '') + ' ' + hit.source.name;
        result.route = '/project/' + hit.source.project_id + '/bhb/' + hit.source.position_id;
        result.ctx = { task_id: hit.id };
        break;
      default:
        result.name = 'Unnamed';
        result.route = '/';
        break
    }
    return result;
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num - 3) + '...'
  }
}
