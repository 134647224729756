import Vue from 'vue'
import { formatDate, formatDateTime } from '@/util/data'

Vue.filter('date', function (value) {
  if (!value) return ''
  return formatDate(value);
})


Vue.filter('datetime', function (value) {
  if (!value) return ''
  return formatDateTime(value);
})

Vue.filter('currency', (value, currency = 'EUR', currencyDisplay = 'code') => {
  let v = 0
  try {
    if (typeof value === 'string') {
      v = parseInt(value)
    }
    v = value
  } catch (error) {
    v = 0
  }

  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currencyDisplay,
    currency,
  }).format(v)
})

Vue.filter('number', (value) => {
  let v = 0
  if (value === null) {
    return ''
  }

  try {
    if (typeof value === 'string') {
      v = parseInt(value)
    } else {
      v = value
    }
  } catch (error) {
    v = 0
  }
  
  return v.toLocaleString('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 0
  })
})

Vue.filter('fileSize', size => {
    if (size < 1024) {
      return size + ' B'
    }
    const i = Math.floor(Math.log(size) / Math.log(1024))
    let num = (size / Math.pow(1024, i))
    const round = Math.round(num)
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
    return `${num} ${'KMGTPEZY'[i-1]}B`
})

Vue.filter('material', (mat, lang = 'de') => {
  if (mat.variant==null) return mat.mat_no;
  if (mat.variant.InternalDescription == null) return mat.VariantCode
  return lang === 'de' ? mat.variant.InternalDescription : mat.variant.Text_en
})

Vue.filter('firstmaterial', (pos, type, lang = 'de') => {
  if (!pos.materials) return null
  const mats = pos.materials.filter(m=>m.type==type)
  if (mats.length==0) return null
  const mat = mats[0]
  if (mat.variant==null) return mat.mat_no
  if (mat.variant.InternalDescription==null) return mat.VariantCode
  return lang === 'de' ? mat.variant.InternalDescription : mat.variant.Text_en
  // const fields = mat.variant.InternalDescription.split(',')
  // if (fields.length>=2) {
  //   return fields[1]
  // } else {
  //   return fields[0]
  // }
})

Vue.filter('category', (mat, lang = 'de') => {
  if (mat.category==null) return '';
  return lang === 'de' ? mat.category.name : mat.category.name_en;
})