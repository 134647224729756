
import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import Vue from "vue";

// change in dev
import validIMCategories from './im-categories'
import validPickingListCategories from './picking-list-categories'
import validOfferCategories from './offer-categories'
import validLoadingListCategories from './loading-list-categories'
import filterByCategory from './filter-by-category'
import posPerMat from './pos-per-material'
import groupByVariant from './group-by-variant'
// merged from pt-api, but not written there?
import { PRODUKTIONSWARE, VERBRAUCHSMATERIAL, EINKAUFSWARE, EXTERNES_LAGER, ANGEBOTSARTIKEL, LAGER_UND_PRODUKTION, LAGERWARE, BESTANDSWARE, WERKZEUG, INHOUSE, INFOARTIKEL } from '../material/materialCategories'

// function createIndex(documents) {
//   if (!Array.isArray(documents)) {
//     return null;
//   }

//   const base = Vue.http.options.root + "../../storage/position/";

//   return documents.reduce((index, document) => {
//     const { item_id, name, path } = document;
//     document.url = `${base}${item_id}/${path}/${name}`;
//     return {
//       ...index,
//       [path]: [
//         ...path in index ? index[path] : [],
//         document
//       ]
//     };
//   }, {});
// }


// JVI: had to comment out during resolve...
// const filterByCategory = (positions, categories) => {
//   return positions.filter(p => {
//     if (p.materials) {
//       return filterMaterials(p.materials, categories)
//     } else {
//       return false
//     }
//   })
// }

// const posPerMat = (positions, categories = []) => {
//   const items = [];
//   positions.forEach(pos => {
//     if (pos.materials) {
//       pos.materials.forEach(m => {
//         const item = Object.assign({}, m);
//         item.pos = pos;
//         items.push(item)
//       })
//     }
//   });

//   if (categories.length > 0) {
//     return items.filter(mp => {
//       if (mp.category) {
//         return categories.includes(mp.category.name)
//       }
//       return false
//     })
//   }

//   return items

// }
//
export default merge({
  state: {
    selectedIMPositions: [],
    dataInputCurrentPage: 1,
    dataInputPageSize: 10,
    IMCurrentPage: 1,
    IMPageSize: 10
  },
  getters: {
    selectedIMPositions: state => state.selectedIMPositions,
    dataInputCurrentPage: state => state.dataInputCurrentPage,
    dataInputPageSize: state => state.dataInputPageSize,
    IMCurrentPage: state => state.IMCurrentPage,
    IMPageSize: state => state.IMPageSize,
    projectAddressPositions(state, rootState) {
      let positions = (rootState.currentProjectAddress === 0
        ? state.positions
        : state.positions.filter(p => {
          return p.address ? p.address.address_id === rootState.currentProjectAddress : false
        }))

      positions = positions.sort((a, b) => a.sort - b.sort)
      return positions
    },
    offerPositions(state, rootState) {
      return filterByCategory(rootState.projectAddressPositions, validOfferCategories)
    },
    IMPositions(state, rootState) {
      return filterByCategory(rootState.projectAddressPositions, validIMCategories)
        .filter(item => item.divider_sheet !== 1);
    },
    unrejectedIMPositions(state, rootState) {
      return filterByCategory(rootState.projectAddressPositions, validIMCategories)
        .filter(item => item.state_bhb !== 'ABGELEHNT')
        .filter(item => item.divider_sheet !== 1);
    },
    positionPerMaterial(state, rootState) {
      return posPerMat(rootState.projectAddressPositions)
    },
    pickingListPositions(state, rootState) {
      return groupByVariant(posPerMat(rootState.projectAddressPositions, validPickingListCategories))
    },
    loadingListPositions(state, rootState) {
      return groupByVariant(posPerMat(rootState.projectAddressPositions, validLoadingListCategories))
    },
    reStrorageListPositions(state, rootState) {
      return groupByVariant(posPerMat(rootState.projectAddressPositions))
    },
    bhbStates(state, rootState) {
      const result = {};
      rootState.authorisedStateValues.map(s => {
        result[s] = filterByCategory(rootState.projectAddressPositions, validIMCategories).filter(p => {
          const state = p.state_bhb || 'LEER'
          return state.toLowerCase() === s.toLowerCase()
        }).length
      })
      return result
    },
    kommStates(state, rootState) {
      const result = {};
      rootState.kommStateValues.map(s => {
        result[s] = posPerMat(rootState.projectAddressPositions, validPickingListCategories).filter(item => {
          return item.state_komm === s && item.pos.rel_business
        }).length
      })

      return result
    },
    konfStates(state, rootState) {
      const result = {};
      rootState.konfStateValues.map(s => {
        result[s] = rootState.positionPerMaterial.filter(item => {
          return item.pos.pt_konfektionsstatus === s
        }).length
      })

      return result
    },
    ladelisteStates(state, rootState) {
      const result = {};
      rootState.ladeStateValues.map(s => {
        result[s] = posPerMat(rootState.projectAddressPositions, validLoadingListCategories).filter(item => {
          return item.state_lade === s && item.state_komm === 'KOMMISSIONIERT'
        }).length
      })

      return result
    },
    montageStates(state, rootState) {
      const result = {};
      const stateMapping = {
        'OFFEN': false,
        'MONTIERT': true
      }

      rootState.montageStateValues.map(s => {
        result[s] = filterByCategory(rootState.projectAddressPositions, validIMCategories)
          .filter(s => s.state_bhb !== 'ABGELEHNT')
          .filter(item => {
            return item.mounted == stateMapping[s]
          }).length
      })

      return result
    },
    ruecklagerungStates(state, rootState) {
      const result = {};
      rootState.ruecklagerungStateValues.map(s => {
        result[s] = rootState.positionPerMaterial.filter(item => {
          return item.state_rueck === s && item.state_lade === 'GELADEN'
        }).length
      });
      return result

    }
  },
  actions: {
    loadPositionsForPorject({ commit, dispatch }, projectId) {
      return dispatch('findPositions', {
        data: {
          'and': {
            'project_id': projectId
          },
          'order': {
            'sort': 'ASC'
          },
          'with': {
            'order': {
              'one': 'order',
              'this': 'order_no',
              'that': 'No'
            },
            'offer': {
              'one': 'offer',
              'this': 'quote_no',
              'that': 'No'
            },
            'ride': {
              'one': 'ride',
              'this': 'ride_id',
              'query': {
                'with': {
                  'vehicle': { 'one': 'vehicle', 'this': 'vehicle_id' }
                }
              }
            },
            'area': {
              'one': 'option',
              'this': 'area_id',
            },
            'color': {
              'one': 'option',
              'this': 'color_id',
            },
            'bhb_position': {
              'one': 'option',
              'this': 'bhb_position_id',
            },
            'assembly_info': {
              'one': 'option',
              'this': 'assembly_info_id',
            },
            "address": {
              "one": "project_address",
              "this": "p_address_id",
              "that": 'id',
              'query': {
                'with': {
                  'address': {
                    'one': 'address',
                    'this': 'address_id',
                    'that': 'id'
                  },
                }
              }
            },
            'documents': {
              'many': 'document',
              'this': 'id',
              'that': 'item_id',
              'query': {
                'and': {
                  'type': 'position',
                }
              }
            },
            'materials': {
              'many': 'material',
              'that': 'position_id',
              'query': {
                'with': {
                  'article': {
                    'one': 'article',
                    'this': 'mat_no',
                    'that': 'No'
                  },
                  'variant': {
                    'one': 'variant',
                    'this': 'VariantCode',
                    'that': 'Code'
                  },
                  'ride': {
                    'one': 'ride',
                    'this': 'ride_id',
                    'query': {
                      'with': {
                        'vehicle': { 'one': 'vehicle', 'this': 'vehicle_id' }
                      }
                    }
                  },
                  'category': {
                    'one': 'option',
                    'this': 'category_id',
                  },
                  'user_komm_ref': {
                    'one': 'users',
                    'this': 'user_komm'
                  },
                  'user_lade_ref': {
                    'one': 'users',
                    'this': 'user_lade'
                  },
                  'user_rueck_ref': {
                    'one': 'users',
                    'this': 'user_rueck'
                  }
                },
                order: {
                  'sort': 'ASC',
                }
              }
            },
          }
        }
      })
        .then(positions => {
          commit('positions', positions);
          return positions
        })
    },
    updatePositionForProject({ commit, dispatch }, { position, isUpdate }) {
      return dispatch('findPositions', {
        data: {
          'and': {
            'id': position.id,
          },
          'order': {
            'sort': 'ASC'
          },
          'with': {
            'ride': {
              'one': 'ride',
              'this': 'ride_id',
              'query': {
                'with': {
                  'vehicle': { 'one': 'vehicle', 'this': 'vehicle_id' }
                }
              }
            },
            'area': {
              'one': 'option',
              'this': 'area_id',
            },
            'color': {
              'one': 'option',
              'this': 'color_id',
            },
            'bhb_position': {
              'one': 'option',
              'this': 'bhb_position_id',
            },
            'assembly_info': {
              'one': 'option',
              'this': 'assembly_info_id',
            },
            "address": {
              "one": "project_address",
              "this": "p_address_id",
              "that": 'id',
              'query': {
                'with': {
                  'address': {
                    'one': 'address',
                    'this': 'address_id',
                    'that': 'id'
                  },
                }
              }
            },
            'documents': {
              'many': 'document',
              'this': 'id',
              'that': 'item_id',
              'query': {
                'and': {
                  'type': 'position',
                }
              }
            },
            'materials': {
              'many': 'material',
              'that': 'position_id',
              'query': {
                'with': {
                  'article': {
                    'one': 'article',
                    'this': 'mat_no',
                    'that': 'No'
                  },
                  'variant': {
                    'one': 'variant',
                    'this': 'VariantCode',
                    'that': 'Code'
                  },
                  'ride': {
                    'one': 'ride',
                    'this': 'ride_id',
                    'query': {
                      'with': {
                        'vehicle': { 'one': 'vehicle', 'this': 'vehicle_id' }
                      }
                    }
                  },
                  'category': {
                    'one': 'option',
                    'this': 'category_id',
                  },
                  'user_komm_ref': {
                    'one': 'users',
                    'this': 'user_komm'
                  }
                },
                order: {
                  'sort': 'ASC',
                }
              }
            },
          }
        },
        options: {
          commit: false
        }
      })
        .then(positions => {

          commit('UPDATE_POSITION', { position: positions[0], isUpdate });
          return positions[0]
        })
    },
    getBhbPosition({ commit, dispatch }, posId) {
      return dispatch('findPositions', {
        data: {
          'and': {
            'id': posId
          },
          'with': {
            'order': {
              'one': 'order',
              'this': 'order_no',
              'that': 'No'
            },
            'documents': {
              'many': 'document',
              'this': 'id',
              'that': 'item_id',
              'query': {
                'and': {
                  'type': 'position',
                }
              }
            },
            'materials': {
              'many': 'material',
              'that': 'position_id',
              'query': {
                'with': {
                  'article': {
                    'one': 'article',
                    'this': 'mat_no',
                    'that': 'No'
                  },
                  'variant': {
                    'one': 'variant',
                    'this': 'VariantCode',
                    'that': 'Code'
                  },
                  'ride': {
                    'one': 'ride',
                    'this': 'ride_id',
                    'query': {
                      'with': {
                        'vehicle': { 'one': 'vehicle', 'this': 'vehicle_id' }
                      }
                    }
                  },
                  'category': {
                    'one': 'option',
                    'this': 'category_id',
                  },
                },
                order: {
                  'sort': 'ASC',
                }
              }
            },
            'area': {
              'one': 'option',
              'this': 'area_id',
            },
            'color': {
              'one': 'option',
              'this': 'color_id',
            },
            'bhb_position': {
              'one': 'option',
              'this': 'bhb_position_id',
            },
            'assembly_info': {
              'one': 'option',
              'this': 'assembly_info_id',
            },
            'user_rel_business': {
              'one': 'users',
              'this': 'user_rel_business_id',
            },
            'user_rel_content': {
              'one': 'users',
              'this': 'user_rel_content_id',
            },
            'user_rejected': {
              'one': 'users',
              'this': 'user_rejected_id',
            },
            'address': {
              "one": "project_address",
              "this": "p_address_id",
            },

          }
        },
        options: {
          commit: false
        }
      })
        .then(res => {
          commit('position', res[0])
          return res[0]
        })
    },
    updatePositionSort({ state }) {
      const data = {};
      state.positions.forEach((pos, i) => {
        pos.sort = i;
        data[pos.id] = { sort: pos.sort }
      });
      return Vue.http.put(`position`, data)
    },
    toggleWirtschaftlicheFreigabe({ commit, dispatch }, { posId, position }) {
      const b_state = !position['rel_business'];
      return dispatch('updatePosition', {
        id: posId, data: {
          'rel_business': b_state
        }
      })
        .then(() => commit('UPDATE_POSITION_WIRTSCHAFTLICH', { posId, b_state }))
    },
    bulkWirtschaftlicheFreigabe({ commit, state }, posIds) {
      const data = {};
      posIds
        .map(id => state.positions.find(p => p.id == id))
        .forEach(pos => {
          commit('UPDATE_POSITION_WIRTSCHAFTLICH', { posId: pos.id, b_state: true });
          data[pos.id] = { rel_business: true }
        });
      return Vue.http.put(`position`, data)
    },
    bulkWirtschaftlicheFreigabeAndInhaltlicheFreigabe({ commit, state }, posIds) {
      const data = {};
      posIds
        .map(id => state.positions.find(p => p.id == id))
        .forEach(pos => {
          commit('UPDATE_POSITION_WIRTSCHAFTLICH', { posId: pos.id, b_state: true })
          commit('UPDATE_POSITION_BHB_STATE', { posId: pos.id, bhbState: 'FREIGEGEBEN' })
          data[pos.id] = { rel_business: true, state_bhb: 'FREIGEGEBEN' }
        });
      return Vue.http.put(`position`, data)
    },
    bulkMountedState({ commit, state }, posIds) {
      const data = {};
      posIds
        .map(id => state.positions.find(p => p.id == id))
        .forEach(pos => {
          commit('UPDATE_POSITION_BHB_MOUNTED_STATE', { posId: pos.id, mountedState: true })
          data[pos.id] = { mounted: true }
        });
      return Vue.http.put(`position`, data)
    },
    bulkQuoteNo({ commit, state }, { posIds, quoteNo }) {
      const data = {};
      posIds
        .map(id => state.positions.find(p => p.id == id))
        .forEach(pos => {
          commit('UPDATE_QUOTE_NO', { posId: pos.id, quote_no: quoteNo });
          data[pos.id] = { quote_no: quoteNo }
        });
      return Vue.http.put(`position`, data)
    },
    setBhbState({ commit, dispatch }, { posId, bhbState }) {
      return dispatch('updatePosition', {
        id: posId, data: {
          'state_bhb': bhbState
        }
      })
        .then(() => commit('UPDATE_POSITION_BHB_STATE', { posId, bhbState }))
    },
    setBhbStateBulk({ commit, dispatch }, { posIds, bhbState }) {
      const data = {}
      posIds.map(id => {
        data[id] = {
          'state_bhb': bhbState
        }
      })
      return dispatch('updatePosition', {
        data
      })
        .then(() => Promise.all(posIds.map(posId => commit('UPDATE_POSITION_BHB_STATE', { posId, bhbState }))))
    },
    clearSelectedIMPositions({ commit }) {
      commit('UPDATE_SELECTED_IM_POSITIONS', [])
    },
    setBhbActionPL({ commit, dispatch }, { posId, actionPL }) {
      return dispatch('updatePosition', {
        id: posId, data: {
          'handlung_pl': actionPL
        }
      })
        .then(() => commit('UPDATE_POSITION_HANDLUNG_PL', { posId, actionPL }))
    },
    setBhbMountedState({ commit, dispatch }, { posId, mountedState }) {
      return dispatch('updatePosition', {
        id: posId, data: {
          'mounted': mountedState
        }
      })
        .then(() => commit('UPDATE_POSITION_BHB_MOUNTED_STATE', { posId, mountedState }))
    },
    setBhbMountedOk({ commit, dispatch }, { posId, isOk }) {
      return dispatch('updatePosition', {
        id: posId,
        data: {
          'mounted_ok': isOk
        }
      })
        .then(() => commit('UPDATE_POSITION_BHB_MOUNTED_OK', { posId, isOk }))
    },
    setBhbHasDebriefing({ commit, dispatch }, { posId }) {
      return dispatch('updatePosition', {
        id: posId,
        data: {
          'has_debriefing': true
        }
      })
        .then(() => commit('UPDATE_POSITION_HAS_DEBRIEFING', { posId, hasDebriefing: true }))
    },
    setBhbDvErstelltState({ commit, dispatch }, { posId, dvErstelltState }) {
      return dispatch('updatePosition', {
        id: posId, data: {
          'dv_erstellt': dvErstelltState
        }
      })
        .then(() => commit('UPDATE_POSITION_BHB_DV_ERSTELLT_STATE', { posId, dvErstelltState }))
    }
  },
  mutations: {
    'SET_DATA_INPUT_CURRENT_PAGE'(state, val) {
      state.dataInputCurrentPage = val
    },
    'SET_DATA_INPUT_PAGE_SIZE'(state, val) {
      state.dataInputPageSize = val
      state.dataInputCurrentPage = 1
    },
    'SET_IM_CURRENT_PAGE'(state, val) {
      state.IMCurrentPage = val
    },
    'SET_IM_PAGE_SIZE'(state, val) {
      state.IMPageSize = val
      state.IMCurrentPage = 1
    },
    createPosition(state, p) {
      state.positions.push(p)
    },
    UPDATE_POSITION_BHB_STATE(state, { posId, bhbState }) {
      const pos = state.positions.find(p => p.id === posId);
      if (pos) {
        pos.state_bhb = bhbState
      }
    },
    UPDATE_POSITION_HANDLUNG_PL(state, { posId, actionPL }) {
      const pos = state.positions.find(p => p.id === posId);
      if (pos) {
        pos.handlung_pl = actionPL
      }
    },
    UPDATE_POSITION_BHB_MOUNTED_STATE(state, { posId, mountedState }) {
      const pos = state.positions.find(p => p.id === posId);
      pos.mounted = mountedState
    },
    UPDATE_POSITION_BHB_MOUNTED_OK(state, { posId, isOk }) {
      const pos = state.position.find(p => p.id === posId);
      pos.mounted_ok = isOk;
    },
    UPDATE_POSITION_HAS_DEBRIEFING(state, { posId, hasDebriefing }) {
      const pos = state.position.find(p => p.id === posId);
      pos.has_debriefing = hasDebriefing;
    },
    UPDATE_POSITION_BHB_DV_ERSTELLT_STATE(state, { posId, dvErstelltState }) {
      const pos = state.positions.find(p => p.id === posId);
      pos.dv_erstellt = dvErstelltState
    },
    UPDATE_POSITION_WIRTSCHAFTLICH(state, { posId, b_state }) {
      const pos = state.positions.find(p => p.id === posId);
      pos.rel_business = b_state
    },
    UPDATE_QUOTE_NO(state, { posId, quoteNo }) {
      const pos = state.positions.find(p => p.id === posId);
      pos.quote_no = quoteNo
    },
    UPDATE_SELECTED_IM_POSITIONS(state, positionIds) {
      state.selectedIMPositions = positionIds
    },
    UPDATE_POSITION(state, { position, isUpdate }) {
      if (isUpdate) {
        state.positions = [
          ...state.positions.map(item => item.id !== position.id ? item : { ...item, ...position })
        ]
      } else {
        state.positions.push(position)
      }
    }
  }
}, Base('position'))

function createIndex(documents) {
  if (!Array.isArray(documents)) {
    return null;
  }

  const base = Vue.http.options.root + "../../storage/position/";

  return documents.reduce((index, document) => {
    const { item_id, name, path } = document;
    document.url = `${base}${item_id}/${path}/${name}`;
    return {
      ...index,
      [path]: [
        ...path in index ? index[path] : [],
        document
      ]
    };
  }, {});
}