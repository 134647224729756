<template>
  <base-dashboard>
    <slot/>
  </base-dashboard>
</template>
<script>
  // Charts
  // import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';

  // // Components
  // import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';

  // // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';
  import  ProjectTable from '@/components/ProjectTable'
  import  FavProjectTable from '@/components/FavProjectTable'

  import projects from '@/data/projects'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import VueGridLayout from 'vue-grid-layout'

  export default {
    name: 'CustomizableDashboard',
    components: {
      ProjectTable,
      FavProjectTable,
      RouteBreadCrumb,
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem
    },
    computed: {
      favoriteProjects () {
        return this.data.projects.filter(item => item.isFavorite === true)
      }
    },
    data() {
      return {
        data: {
          projects
        }
      };
    },
    methods: {

    },
    mounted() {
    }
  };
</script>
<style>


@media (max-width: 1291px) {
  .vue-grid-layout {
    height: auto !important;
  }
  .vue-grid-layout .vue-grid-item {
    position: static !important;
    padding: 6px;
    float: left;
    width: 50% !important;
  }
  .positioning_button {
    display: none;
  }
}

@media (max-width: 859px) {
  .vue-grid-item .card {
    height: auto;
    min-height: 500px;
  }
  .vue-grid-layout .vue-grid-item {
    margin: 6px 0;
    float: none;
    width: 100% !important;
    height: auto !important;
    min-height: 500px;
  }
}


</style>
