import Vue from 'vue'
import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import moment from 'moment'

function daysDiff (milestone, round = false) {
    const now = moment();
    const then = moment(milestone.due);
    const diff = then.diff(now, 'days', true)
    if (round) {
      return Math.round(diff)
    }
    return diff
}

export default merge({
    getters: {
        widgetMilestones: (state, rootState) => {
            const result = state.milestones
                .filter(i => {
                    if (i.internal && !rootState.currentUserRights.includes('project.milestones.canseeinternal')) {
                        return false
                    }
                    
                    return true
                })
                .map(i => {
                    const diff = daysDiff(i)
                    i.dueDays = diff >= 1 ? Math.round(diff) : diff.toFixed(1)
                    return i
                }).sort((a, b) => {
                    return a.dueDays - b.dueDays
                })

            return (rootState.currentProjectAddress === 0
                ? result
                : result.filter(p => {
                  return p.p_address_id === rootState.currentProjectAddress
                }))
        }
    },
    actions: {
        getMilestonesForProject({ dispatch, commit }, projectId) {
            return dispatch('findMilestones', {
                data: {
                    select: ['id','name','project_id','due','p_address_id'],
                    'and': {
                        'project_id': projectId
                    },
                    'with': {
                        'tasks': {
                            'many': 'task',
                            'this': 'id',
                            'that': 'milestone_id',
                            query: {
                                select: ['id','milestone_id','state','due']
                            }
                        },
                        'projectAddress': {
                            'one': 'project_address',
                            'this': 'p_address_id',
                            'that': 'id',
                            'query': {
                                select: ['id', 'address_id', 'date_from', 'date_to'],
                                'with': {
                                    "address": {
                                        "one": "address",
                                        "this": "address_id",
                                        "that": 'id',
                                        query: {
                                            select: ['id','name']
                                          }
                                    }
                                }
                            }
                          },
                    }
                }
            })
            .then(results => {
                commit('milestones', results)
                return results
            })
        }
    }
}, Base('milestone'))


// id	integer
// Unique id of the item.

// client_id	integer
// Client the item lives in.

// project_id	integer
// Das Projekt.

// type	string
// MILE,DRUCK,EINTREFF,START,ENDE,TERMIN...

// phase	string
// Phase des Projektes: BHB, LADE, ...

// name	string
// Name des Termins.

// due	date
// Datum des Termins.

// p_address_id	integer
// Einsatzort.

// }