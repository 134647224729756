<template>
  <div class="form-group">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{label}}
        <span v-if="validator && validator.$params.required">*</span>
      </label>
    </slot>
    <div
      :class="[
       {'input-group': hasIcon},
       {'focused': focused},
       {'input-group-alternative': alternative},
       {'has-label': label || $slots.label},
       inputGroupClasses,
       {'el-error' : validator && validator.$dirty && validator.$invalid}
       ]"
    >
      <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
        <slot name="prepend">
          <span class="input-group-text">
            <i :class="prependIcon"></i>
          </span>
        </slot>
      </div>
      <slot v-bind="slotData">
        <input
          ref="input"
          :value="value"
          :type="type"
          v-on="listeners"
          v-bind="$attrs"
          :valid="!error"
          :required="required"
          :maxlength="maxLength"
          class="form-control"
          :class="[{'is-valid': validator && !validator.$invalid }, {'is-invalid': validator && validator.$invalid }, inputClasses]"
          @wheel="preventScroll"
        />
      </slot>
      <div v-if="appendIcon || $slots.append" class="input-group-append">
        <span class="input-group-text">
          <slot name="append">
            <i :class="appendIcon"></i>
          </slot>
        </span>
      </div>
      <slot name="infoBlock"></slot>
      <slot name="error">
        <div v-if="error" class="invalid-feedback" style="display: block;">{{ error }}</div>
      </slot>
      <slot name="success">
        <div class="valid-feedback" v-if="!error && valid">{{successMessage}}</div>
      </slot>
      <div v-if="validator && validator.$dirty && validator.$invalid" class="invalid-feedback" style="display: block;">
        <span v-if="!validator.required">Bitte geben Sie einen Wert ein.</span>
        <span
          v-if="!validator.minLength && validator.$params.minLength"
        >Bitte geben Sie mindestens {{validator.$params.minLength.min}} Zeichen ein.</span>
        <span
          v-if="!validator.maxLength && validator.$params.maxLength"
        >Bitte geben Sie höchstens {{validator.$params.maxLength.max}} Zeichen ein.</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)"
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    group: {
      type: Boolean,
      description:
        "Whether input is an input group (manual override in special cases)"
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout"
    },
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    error: {
      type: String,
      description: "Input error (below input)"
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: ""
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    inputGroupClasses: {
      type: String,
      description: "Input group css classes"
    },
    value: {
      type: [String, Number],
      description: "Input value"
    },
    maxLength: {
      type: Number,
      default: 999999
    },
    type: {
      type: String,
      description: "Input type",
      default: "text"
    },
    appendIcon: {
      type: String,
      description: "Append icon (right)"
    },
    prependIcon: {
      type: String,
      description: "Prepend icon (left)"
    },
    validator: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== undefined ||
        this.prependIcon !== undefined ||
        this.group
      );
    }
  },
  watch: {
    isFocused () {
      console.log(this.isFocused)
      if (this.isFocused) {
        this.$refs.input.$el.focus()
      }
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
    preventScroll(e) {
      e.currentTarget.blur()
    }
  }
};
</script>
<style scoped>
/* .el-error {
  background-color: lightgoldenrodyellow;
} */
</style>
