var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn",class:[
    { 'btn-icon btn-fab': _vm.icon },
    ( _obj = {}, _obj[("btn-" + _vm.type)] = _vm.type, _obj ),
    ( _obj$1 = {}, _obj$1[("btn-" + _vm.size)] = _vm.size, _obj$1 ),
    { disabled: _vm.disabled && _vm.tag !== 'button' }
    ],attrs:{"type":"button"},on:{"click":_vm.onPrimaryClick}},[_vm._t("content",function(){return [_vm._v(" "+_vm._s(_vm.text)+" ")]})],2),_c('button',{staticClass:"btn dropdown-toggle dropdown-toggle-split",class:'btn-' + _vm.type,attrs:{"type":"button","aria-haspopup":"true","aria-expanded":"false"},on:{"click":_vm.toggleDropdown}},[_c('span',{staticClass:"sr-only"},[_vm._v("Toggle Dropdown")])]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],ref:"dropdown",staticClass:"dropdown-menu",class:[{ 'dropdown-menu-right': _vm.menuOnRight }]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }