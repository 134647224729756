export default function createComment(defaults = {}) {
  return {
    client_id: 0,
    comment: '',
    created: '',
    id: 0,
    internal: false,
    position_id: null,
    pred_id: null,
    project_id: null,
    subject: '',
    task_id: null,
    user_id: 0,
    ...defaults
  }
}
