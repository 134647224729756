<template>
  <div
    class="media media-comment" :id="'comment-' + comment.id" 
    :class="{
      'search-hit': searchCtx.comment_id == comment.id,
      'external-comment': !comment.internal,
    }"
    v-if="isVisible"
  >
    <!-- <img class="avatar avatar-sm media-comment-avatar rounded-circle" :src="userImage" /> -->
    <avatar :user-id="comment.user.id"></avatar>
    <div class="media-body">
      <div class="d-flex justify-content-between footer-row mb-2">
        <div class="h5 m-0">
          <span
            class="text-bold"
            style="color: #9f2f37"
            v-if="comment.user.id === 33"
            >{{ comment.user.name }}</span
          >
          <span v-else>{{ comment.user.name }}</span>
        </div>
        <div class="h5 m-0">
          <small class="text-muted">
            <i class="fas fa-clock mr-1"></i>

            {{ comment.created | datetime }}
          </small>
        </div>
      </div>
      
      <router-link :to="`/project/${$route.params.id}/bhb/${comment.position_id}`" v-if="comment.type == 'DEBRIEFING'" class="small">{{ debriefingPositon }}</router-link>

      <h4 v-if="comment.subject" class="mt-3 mb-1"> {{ comment.subject }}</h4>
      <div
        class="media-comment-text text-sm lh-160" :class="{'correction': comment.type === 'KORR_COMMENT','completed': comment.completed}"
        v-html="comment.comment"
      >
      
     </div>
      <attachments v-if="attachments" :documents="attachments"></attachments>
      <div class="icon-actions mt-2">
        <a class="clickable text-muted" @click="onAnswerClick(comment)">
          <i class="ni ni-curved-next ml-0"></i>
          <span class="text-muted">{{ $t("message.reply") }}</span>
        </a>
        <a
          class="clickable text-muted"
          @click="onDeleteClick(comment)"
          v-if="currentUserRights.includes('im.comment.candelete')"
        >
          <i class="fas fa-trash ml-0"></i>
          <span class="text-muted">{{ $t("message.delete") }}</span>
        </a>
        <a
          class="clickable text-muted"
          @click="createTaskFromComment(comment)"
          v-if="currentUserRights.includes('im.comment.cancreatetask')"
        >
          <i class="fas fa-tasks ml-0"></i>
          <span class="text-muted">{{ $t("message.create_task") }}</span>
        </a>
        <span class="ml-3" v-if="comment.type === 'KORR_COMMENT' && !comment.completed && currentUserRights.includes('im.comment.cancreatetask')">
          <el-checkbox
            @change="changeState(comment)"
            :label="$t('message.korrektur_done')"
          ></el-checkbox>
        </span>
        <span class="ml-2 small" v-if="comment.type === 'KORR_COMMENT' && comment.completed">
          <i class="fas fa-check"></i>
          {{ $t('message.komm_checked') }} {{ comment.completed | datetime }}
        </span>
      </div>
      <comment-form
        :placeholder="$t('message.reply')"
        :text="newText"
        v-if="isAnswerVisible"
        @saved="isAnswerVisible = false"
        :force-internal="comment.internal"
        :createComment="createCommentCallback"
        :triggerReload="triggerReload"
        :attachmentUploaded="onUpload"
        :at-values="atValues"
      ></comment-form>
    </div>

    <comment
      @emitComment="newTaskFromComment"
      class="child"
      user-image="/images/bild1.png"
      v-for="child in comment.children"
      :is-answer-own="comment.user.id === currentUser.id"
      :at-values="atValues"
      :comment="child"
      :triggerReload="triggerReload"
      :createComment="createCommentCallback"
      :key="child.id"
    />
  </div>
</template>

<script>
import CommentForm from "./CommentForm";
import Attachments from "./AttachmentTable";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

const stripHTML = (html) => {
  if (html == null) return "";
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export default {
  name: "comment",
  components: {
    CommentForm,
    Attachments,
  },
  props: {
    atValues: {
      type: Array,
    },
    isAnswerOwn: {
      type: Boolean,
      default: false,
    },
    createComment: {
      type: Function,
    },
    triggerReload: {
      type: Function,
    },
    comment: {
      type: Object,
    },
    userImage: {
      type: String,
      default: "img/theme/team-1.jpg",
    },
  },
  data() {
    return {
      isAnswerVisible: false,
      attachments: [],
      debriefingPositon: ''
    };
  },
  computed: {
    ...mapGetters(["currentUserRights", "currentUser", "docs", "searchCtx"]),
    isVisible() {
      if (!this.comment) {
        return false;
      } else if (
        this.comment.user.id === this.currentUser.id ||
        this.isAnswerOwn
      ) {
        if (this.currentUserRights.includes("bhb.comment.canseeown")) {
          return true;
        }
      } else if (this.comment.internal) {
        if (
          this.currentUserRights.includes(
            "BHB - Vollbild_Kommentarfunktion Intern sehen"
          )
        ) {
          return true;
        }
        if (this.comment.comment.includes(`data-id="${this.currentUser.id}"`)) {
          return true;
          // return false
        }
        // return false
      } else if (!this.comment.internal) {
        if (
          this.currentUserRights.includes(
            "BHB - Vollbild_Kommentarfunktion Kunde sehen"
          )
        ) {
          return true;
        }
        if (this.comment.comment.includes(`data-id="${this.currentUser.id}"`)) {
          return true;
          // return false
        }
        // return false
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["deleteComment", "loadPositionsForPorject"]),
    async createCommentCallback(comment) {
      const result = await this.createComment({
        pred_id: this.comment.id,
        ...comment,
      });

      return result;
    },
    onAnswerClick(comment) {
      this.isAnswerVisible = !this.isAnswerVisible;
      this.newText = `
      <p>
        <span class="mention" data-index="1" data-denotation-char="@" data-id="${comment.user.id}" data-value="${comment.user.name}">
          <span contenteditable="false">
            <span class="ql-mention-denotation-char">@</span>
            ${comment.user.name}
          </span>
        </span>
      </p>
    `;
    },
    onUpload: async function(){
      this.triggerReload();
    },
    async getDebriefingPosition() {
      let posForPro = await this.loadPositionsForPorject(this.comment.project_id);
      let pos = posForPro.filter(p => p.id == this.comment.position_id);
      if (pos.length) {
        this.debriefingPositon = pos[0].key;
      }
    },
    async onDeleteClick(comment) {
      try {
        await this.$confirm(
          this.$t("message.comment_delete_confirm"),
          this.$t("message.warning"),
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning",
          }
        );
      } catch (error) {
        return this.$message({
          type: "info",
          message: "Canceled",
        });
      }
      const childs=this.comment.children;
      
      let childsToDelete = [];

      function recurseChilds(childs){
        for (let i=0; i<childs.length; i++){
          childsToDelete.push(childs[i]);
          let newChilds = childs[i].children;
          recurseChilds(newChilds);
          }
      }

      recurseChilds(childs);

      await this.deleteComment({ id: comment.id });
      
      for (let i=0; i<childsToDelete.length; i++){
          this.deleteComment({ id: childsToDelete[i].id });
      }

      this.triggerReload();
    },
    createTaskFromComment(comment) {
      const text = String(stripHTML(comment.comment).substring(0, 50));
      const thisComment = comment;
      this.$emit('emitComment', text, thisComment);

    },

    changeState(item) {
      if (item.id) {
        item.completed = moment().format("YYYY-MM-DD HH:mm:ss");
        this.$store.dispatch("updateComment", {
          id: item.id,
          data: { completed: item.completed }
        });

        let newState = 'OFFEN';
        this.$store.dispatch("updatePosition", {
          id: item.position_id,
          data: { state_bhb: newState }
        });
      this.$emit('stateChanged', newState);
      }
    },
    newTaskFromComment(text, thisComment){
      this.$emit('emitComment', text, thisComment);
    }
  },
  mounted() {
    if (this.searchCtx.comment_id == this.comment.id) {
      this.$el.scrollIntoView();
    }

    if (this.comment.type == 'DEBRIEFING') {
      this.getDebriefingPosition();
    }
    
    this.$store.dispatch("getDocs", {
        type: "comment_attachment",
        id: this.comment.id,
      }).then((item) => {
        this.attachments = _.get(item, ["comment", this.comment.id, "attach"], null);
      });
  },
};
</script>

<style scoped>
.correction{
  background: yellow;
}
.completed{
  background: lightgreen;
}
</style>