import { pick } from 'lodash';

export default function pickTask(model) {
  return pick(model, [
    'id',
    'client_id',
    'has_attachment',
    'project_id',
    'title',
    'description',
    'user_id',
    'milestone_id',
    'due',
    'position_id',
    'state',
    'created_by',
    'interval',
    'interval_repeat',
    'start_interval',
    'end_interval'
  ]);
}
