import GetAuthentication from './auth'
import InitUsersApi from './users'

export default {
    install (Vue) {
        const api = this.initAPI(Vue)
        Vue.api = api
        Vue.prototype.$api = api
    },
    initAPI (Vue) {
        return {
            auth: GetAuthentication(Vue),
            users: InitUsersApi(Vue)
        }
    }
}