import { max } from 'lodash';
import moment from 'moment'

// https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
export function b64toBlob (b64Data, contentType='', sliceSize=512)  {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

export function formatDate(date) {
    
    if (date && !(date instanceof Date)) {
        date = date.replace(/-/g, "/");
        date = new Date(date);
        return moment(date).format('DD.MM.YYYY');
    } else if (date instanceof Date) {
        return moment(date).format('DD.MM.YYYY');
    }
    return ''
}

export function formatDateTime(date) {    
    if (!(date instanceof Date)) {
        date = date.replace(/-/g, "/");
        date = new Date(date);
        return moment(date).format('DD.MM.YYYY, HH:mm:ss');
    }
    return ''
}

export function resizeImage (settings) {
  var file = settings.file;
  var maxSize = settings.maxSize;
  var reader = new FileReader();
  var image = new Image();
  var canvas = document.createElement('canvas');
  var dataURItoBlob = function (dataURI) {
      var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
      var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var max = bytes.length;
      var ia = new Uint8Array(max);
      for (var i = 0; i < max; i++)
          ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
  };
  var resize = function () {
      var width = image.width;
      var height = image.height;
      var dataUrl = '';
      if (width > maxSize || height > maxSize) {
        if (width > height) {
            height *= maxSize / width;
            width = maxSize;
        } else {
            width *= maxSize / height;
            height = maxSize;
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        dataUrl = canvas.toDataURL('image/jpeg');
      } else {
        dataUrl = reader.result;
      }
      
      return dataURItoBlob(dataUrl);
  };
  return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
      }
      reader.onload = function (readerEvent) {
          image.onload = function () { return ok(resize()); };
          image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
  });
}

export function isImage (mimetype) {
    const mimetypes = [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/vnd.microsoft.icon',
        'image/bmp',
        'image/svg+xml',
        'image/webp',
        'image/tiff',
        'application/octet-stream' // for annotations
      ]

      return mimetypes.includes(mimetype)
}

export function isVideo (mimetype) {
    const mimetypes = [
      'video/mp4',
      'video/quicktime',
      'video/x-flv',
      'application/x-mpegURL',
      'video/MP2T',
      'video/3gpp',
      'video/x-msvideo',
      'video/x-ms-wmv'
    ]

    return mimetypes.includes(mimetype)
}