<template>

  <el-popover
    placement="top"
    width="300"
    class="avatar item"
    trigger="click"
    >
    <h4 class="mb-0" v-if="user && user.name">{{user.name}}</h4>
    <p class="text-xs pb-2 border-bottom mb-1" v-if="user && user.position">{{user.position}}</p>
    <p class="text-xs mb-0" v-if="user && user.email"><b>{{$t('message.email')}}:</b> {{user.email}}</p>
    <p class="text-xs mb-0" v-if="user && user.phone"><b>{{$t('message.phone')}}:</b> {{user.phone}}</p>
    <p class="text-xs mb-0" v-if="user && user.office"><b>{{$t('message.office')}}:</b> {{user.office}}</p>

    <el-tooltip :disabled="disabled" effect="light" v-if="user" :content="`${user.name}`" placement="top" slot="reference">
    <div @click="disabled = !disabled" class="avatar-circle-xsm avatar">
      <img :src="user.avatar.url" v-if="user.avatar"/>
      <div class="initials" v-else>{{user.name.substr(0,1).toUpperCase()}}</div>
    </div>
    </el-tooltip>
  </el-popover>



</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from "vuex"

export default {
  name: "avatar",
   props: {
    userId: {
      type: Number
    }
  },
  computed: {
    ...mapGetters([
      'users',
    ]),
    user () {
      const u = this.users.find(u => u.id === this.userId);
      return u
    },
  },
  data() {
    return {
      useTransitions: false,
      disabled: false
    };
  }
};
</script>
<style>
  .avatar-circle-xsm {
    cursor: pointer;
  }

  .el-popover .text-xs {
    font-size: 0.825em;
  }

  .el-tooltip__popper.is-light {
    border: 1px solid #EBEEF5;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }


  .el-tooltip__popper.is-light .popper__arrow {
    border: 1px solid #EBEEF5;
  }
</style>
