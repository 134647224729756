<template>
  <div id="stickyHeader" class="sticky-header header" :class="{[`bg-${type}`]: type}">
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'base-header',
    props: {
      type: {
        type: String,
        description: 'Header background type'
      }
    }, 
    mounted() {
      let that = this;
      window.onscroll = function() {
        that.toggleStickyHeader()
      };
    },

    methods: {
      toggleStickyHeader () {
        let header = document.getElementById("stickyHeader");
        let sticky = header.offsetTop;
        
        if (window.pageYOffset > sticky && window.innerWidth > 767) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      }
    }
  }
</script>
<style>
</style>
