
import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import Vue from "vue";

export default merge({
  actions: {
    loadArticles ({ commit }) {
      return Vue.http.post(`article/query`, {})
        .then(res => res.json())
        .then(articles => {
          commit('articles', articles);
          return articles
        })
    }
  }

}, Base('article'))
