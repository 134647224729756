<template>
  <div v-if="stack" >
      <template v-for="(item, index) in stack" >
        <el-tooltip :content="`${item.name} (${item.progress}%)`" placement="top" :key="index" v-if="currentUserRights.includes(item.right) && project.functions.includes(item.func)">
            <div class="progress clickable" @click="onClick(item)">
                <div class="progress-bar "
                     :class="'progress-bar-ok'"
                     role="progressbar"
                     :style="{ width: item.progress + '%' }"></div>
                <div class="progress-bar "
                     :class="'progress-bar-nok'"
                     role="progressbar"
                     :style="{ width: 100-item.progress + '%' }"></div>
            </div>
        </el-tooltip>
      </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectStatusStackedBar',
  props: {
    progress: {
      type: Object
    },
    project: {
      type: Object
    }
  },

  computed: {
    ...mapGetters([
      'currentUserRights'
    ]),
    stack() {
      const p = this.progress;
      if (!p) return [];
      return [
        {name: this.$t('message.bhb'), progress: percent(p.bhb, p.positions), url: `/project/${this.project.id}/bhb`, right: 'project.bhb.isvisible', func: 'B' },
        {name: this.$t('message.pickingList'), progress: percent(p.komm, p.komm_all), url: `/project/${this.project.id}/picking-list`, right: 'project.kommissionierungsliste.isvisible', func: 'K'},
        {name: this.$t('message.loadingList'), progress: percent(p.lade, p.komm), url: `/project/${this.project.id}/loading-list`, right: 'project.ladeliste.isvisible', func: 'L' },
        {name: this.$t('message.reStorageList'), progress: percent(p.rueck, p.lade), url: `/project/${this.project.id}/re-storage-list`, right: 'project.rueckeinlagerungsliste.isvisible', func: 'U' },
      ];

      function percent(a, b) {
        if(b === 0 || isNaN(a/b)) {
          return 0;
        }
        return Math.round((a / b) * 100);
      }
    }
  },
  methods: {
    onClick (item) {
      this.$router.push(item.url)
    }
  }
};
</script>
<style>
</style>
