const EnvLoader = {
    install (Vue, options) {
        let env = options.envs[process.env.NODE_ENV + '.' + window.location.hostname]
        console.log(process.env.NODE_ENV + '.' + window.location.hostname)
        if(typeof env === 'undefined' ){
            env = options.envs[process.env.NODE_ENV]
        }
        Vue.prototype.$env = env
    },
    env (envs) {
        let env = envs[process.env.NODE_ENV + '.' + window.location.hostname]
        if(typeof env === 'undefined' ){
            env = envs[process.env.NODE_ENV]
        }
        return env
    }
}

export default EnvLoader
