import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({

}, Base('role'))

// const state = {
//     roles: [
//         {
//             id: 123,
//             name: 'Admin',
//             description: 'Administrator role',
//             rights:[]
//         }
//     ]
// }
// const getters = {
//     roles: state => state.roles
// }

// const mutations = {
//     setRoles (state, roles) {
//         state.roles = roles
//     },
//     addRole (state, role) {
//         state.roles.push(role)
//     }
// }

// const actions = {
//     loadRoles ({ commit }) {
//         commit('setProjects', roles)
//     },
//     addRole ({ commit }, role) {
//         commit('addRole', role)
//     }
// }

// export default {
//     state, getters, mutations, actions
// }
