import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({
    actions: {
        loadProjectMappingByChild: async function ({ dispatch }, filter) {
          return await dispatch('findProject_part_mappings', {
            data: {
              and: filter
            },
          });
        },
    }
}, Base('project_part_mappings'))
