<template>
  <div>
    <badge class="badge-dot mr-4" type="">
        <i :class="`bg-danger`"></i>
        <span class="status">BHB</span>
    </badge>
      <badge class="badge-dot mr-4" type="">
        <i :class="`bg-warning`"></i>
        <span class="status">Kommissionierungsliste</span>
    </badge>
      <badge class="badge-dot mr-4" type="">
        <i :class="`bg-info`"></i>
        <span class="status">Ladeliste</span>
    </badge>
      <badge class="badge-dot mr-4" type="">
        <i :class="`bg-success`"></i>
        <span class="status">Rücklagerung</span>
    </badge>
  </div>
</template>
<script>
export default {
  name: 'ProjektStatusBarLegend'
};
</script>
