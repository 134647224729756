import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import moment from "moment";

function createNotificationFilter (state, rootState) {
  return (n => {
    const action = `${n.type}/${n.operation}`;
    if (action === 'comment/mention') {
      if (n.note === 'INTERN' && !rootState.currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Intern sehen')) {
        return false
      }

      if (n.note === 'EXTERN' && !rootState.currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Kunde sehen')) {
        return false
      }
    } else if (action === 'position/state_bhb') {
      if (n.note === 'FREIGEGEBEN' && !rootState.currentUserRights.includes('notification.status-bhb.freigabe')) {
        return false
      }

      if (n.note === 'KORREKTUR' && !rootState.currentUserRights.includes('notification.status-bhb.korrektur')) {
        return false
      }

      if (n.note === 'HANDLUNG_PL' && !rootState.currentUserRights.includes('notification.status-bhb.handlung-pl')) {
        return false
      }

      if (n.note === 'LEER' && !rootState.currentUserRights.includes('notification.status-bhb.leer')) {
        return false
      }

      if (n.note === 'IN_PRODUKTION' && !rootState.currentUserRights.includes('notification.status-bhb.in-produktion')) {
        return false
      }

      if (n.note === 'MONTIERT' && !rootState.currentUserRights.includes('notification.status-bhb.montiert')) {
        return false
      }

      if (n.note === 'OFFEN' && !rootState.currentUserRights.includes('notification.status-bhb.offen')) {
        return false
      }

      if (n.note === 'ABGELEHNT' && !rootState.currentUserRights.includes('notification.status-bhb.ablehnen')) {
        return false
      }
    } else if(action === 'project_person/create') {
      if (!rootState.currentUserRights.includes('notification.project-person.create')) {
        return false
      }
    } 
    return true
  })
}

const sortNotifications = (a, b) => {
  const action = `${a.type}/${a.operation}`;
  return action === 'comment/mention' ? -1 : 1
}


export default merge({
  state: {
    globalNotifications: []
  },
  getters: {
    allNotifications: (state, rootState) => {
      return state.globalNotifications
        .filter(createNotificationFilter(state, rootState))
        .sort(sortNotifications)
    },
    userNotifications: (state, rootState) => {
      return () => {
        const notifications = state.globalNotifications
          .filter(createNotificationFilter(state, rootState))
          .sort(sortNotifications)
          return notifications
      }
    },
    unnreadNotificationsAmountForProject: (state, rootState) => {
      return projectId => {
        return state.globalNotifications
          .filter(n => n.project_id === projectId && n.read_at === null)
          .filter(createNotificationFilter(state, rootState))
          .length
      }
    },
    projectNotifications: (state, rootState) => {
      return projectId => {
        projectId = parseInt(projectId)
        const notifications = state.globalNotifications
          // .filter(n => n.listener_id === rootState.currentUser.id)
          .filter(n => n.project_id === projectId)
          .filter(createNotificationFilter(state, rootState))
          .sort(sortNotifications)

          return notifications
      }
    },
    unreadProjectNotifications: (state, rootState) => {
      return projectId => {
        projectId = parseInt(projectId)
        const notifications = state.globalNotifications
          .filter(n => n.listener_id === rootState.currentUser.id && n.project_id === projectId && n.read_at === null)
          .filter(n => n.user_id !== rootState.currentUser.id)
          .filter(createNotificationFilter(state, rootState))
        return notifications
      }
  
    },
    unreadNotifications: (state, rootState) => rootState.userNotifications.filter(n => n.read_at === null)
  },
  mutations: {
    globalNotifications (state, value) {
      state.globalNotifications = value
    }
  },
  actions: {
    getNotifications({ commit, dispatch }, { user, project_id, mutationName, readOnly }) {
      const query = {
        'and': {
          'read_at': null
        },
        'order': {
          'created_at': 'DESC'
        },
        'with': {
          'user': {
            'one': 'users',
            'this': 'user_id'
          },
          'listener': {
            'one': 'users',
            'this': 'listener_id'
          },
          'position': {
            'one': 'position',
            'this': 'position_id'
          },
          'project': {
            'one': 'project',
            'this': 'project_id'
          },
          'comment': {
            'one': 'comment',
            'this': 'item_id'
          }
        }
      };
      if (user) {
          query.and.listener_id = user.id
      }

      if (project_id) {
         query.and.project_id = project_id;
      }
      
      return dispatch('findNotifications', {
        data: query,
        options: {
          commit: true,
          mutationName
        }
      })
    },
    clearNotifications({ commit, dispatch }, ids) {
      const data = {}
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      ids.forEach(id => data[id] = {
        read_at: now
      })
      return dispatch('updateNotification', { data: data })
    },
  }
}, Base('notification'))
