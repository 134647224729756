import Vue from 'vue'
import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({
  state: {
    projectAddresses: [],
    currentProjectAddress: 0
  },
  getters: {
    projectAddresses: state => state.projectAddresses,
    einsatzorte: state => state.einsatzorte,
    currentProjectAddress: state => state.currentProjectAddress,
    currentProjectAddressName: state => {
      const projectAddresses = state.projectAddresses.find(pa => {
        return pa.address.id === state.currentProjectAddress
      })
      return projectAddresses ? projectAddresses.address.name : ''
    },
    hotels: state => state.projectAddresses.filter(pa => pa.address.type === 'HOTEL'), // Wird scheinbar nicht verwendet?
    locations: state => state.projectAddresses.filter(pa => pa.address.type === 'EINSATZ')
  },
  mutations: {
    projectAddresses (state, addresses) {
      state.projectAddresses = addresses
    },
    SET_CURRENT_PROJECT_ADDRESS (state, address) {
      state.currentProjectAddress = address
    }
  },
  actions: {
    getAddressesByProjectId ({ commit }, projectId) {
      return Vue.http.post(`project_address/query`, {
        "and": {
          "project_id": projectId
        },
        "with": {
          "address": {
            "one": "address",
            "this": "address_id",
            "that": 'id'
          },
          "milestones": {
            "many": "milestone",
            "this": "id",
            "that": 'p_address_id',
            "query": {
              "and": {
                "project_id": projectId
              },
            }
          },
          'documents': {
            'many': 'document',
            'this': 'id',
            'that': 'item_id',
            'query': {
                'and': {
                    'type': 'project_address',
                }
            }
          },
        }
      })
      .then(res => res.json())
      .then(addresses => {

        // const addresses = addressRefs.map(i => {
        //   i.address.milestones = i.milestones;
        //   i.address.p_address_id = i.id;
        //   i.address.p_address = i;
        //   return i.address
        // });

        commit('projectAddresses', addresses);
        return addresses
      })
    }
  }
}, Base('address'))

/**
 *
 * {
  "id": 0,
  "client_id": 0,
  "type": "string",
  "name": "string",
  "street": "string",
  "no": "string",
  "address2": "string",
  "zip": "string",
  "city": "string",
  "country": "string"
}
 */
