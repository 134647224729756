
import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import api from '@/util/api.js'

export default merge({
  actions: {
    loadOrders({ commit, dispatch }, customerNo) {
      return api('findOrders')
        .and('Sell_to_Customer_No', customerNo)
        .run()
        .then(items => {
          commit('orders', items)
          return items
        })
    },
    loadOrderByNo({ commit }, orderNo) {
      return api('findOrders')
        .and('No', orderNo)
        .run()
        .then(items => {
          commit('orders', items)
          return items
        })
    }
  }

}, Base('order'))
